export default {
  namespaced: true,
  state: {
    modals: [
      { name: "createPatient", isOpen: false },
      { name: "createNewPatient", isOpen: false },
      { name: "changeBillingData", isOpen: false },
      { name: "changePassword", isOpen: false },
      { name: "updateUserData", isOpen: false },
      { name: "emptyDoctorsList", isOpen: false },
      { name: "messageRecipientsCount", isOpen: false },
      { name: "referralEditWarning", isOpen: false },
      { name: "filters", isOpen: false },
      { name: "createOwnPackage", isOpen: false },
      { name: "createNewOwnPackage", isOpen: false },
      { name: "deleteNewOwnPackage", isOpen: false },
      { name: "updateNewOwnPackage", isOpen: false },
      { name: "doctorNotActive", isOpen: false },
      { name: "confirmInvoiceIssued", isOpen: false },
      { name: "addInvoiceFile", isOpen: false },
      { name: "completedRegister", isOpen: true },
      { name: "roles", isOpen: false },
      { name: "profileImgCropper", isOpen: false },
      { name: "doctorPrivacyPolicy", isOpen: false },
      { name: "providersTermsOfUse", isOpen: false },
      { name: "infoModal", isOpen: false },
      { name: "userServiceModal", isOpen: false },
      { name: "userDaySchedule", isOpen: false },
      { name: "skipModal", isOpen: false },
      { name: "confirmRemoveServiceModal", isOpen: false },
      { name: "userMedicalCategoriesModal", isOpen: false },
      { name: "manageUserMedicalCategoryModal", isOpen: false },
      { name: "confirmDeleteMedicalCategoryModal", isOpen: false },
      { name: "confirmUpdateMedicalCategoryModal", isOpen: false },
      { name: "deleteItemFromOwnPackage", isOpen: false },
      { name: "occasionalSmsModal", isOpen: false },
      { name: "editPatient", isOpen: false },
      { name: "updateStaffModal", isOpen: false },
      { name: "deleteModal", isOpen: false },
      { name: "aiTypeTextFormModal", isOpen: false },
      { name: "endVisitConfirmationModal", isOpen: false },
      { name: "skipVisitServiceModal", isOpen: false },
      { name: "promotionsStepperModal", isOpen: false },
      { name: "previewSelectedServices", isOpen: false },
      { name: "importServicesDiscounts", isOpen: false },
      { name: "completedMedicalEntityRegisterModal", isOpen: false },
      { name: "addStaffModal", isOpen: false },
      { name: "selectDoctorModal", isOpen: false },
      { name: "selectStatusModal", isOpen: false },
      { name: "errorReloadPageModal", isOpen: false },
      { name: "uploadFileModal", isOpen: false },
      { name: "confirmLeaveTestToDescribe", isOpen: false },
      { name: "editUserDataModal", isOpen: false },
      { name: "confirmModal", isOpen: false },
      { name: "doctorNoMedicalLicenceModal", isOpen: false },
    ],
  },
  getters: {
    isOpenModal: (state) => (name) => {
      return state.modals.find((modal) => modal.name === name).isOpen;
    },
    getModalData: (state) => (name) => {
      return state.modals.find((modal) => modal.name === name);
    },
  },
  mutations: {
    closeModal(state, name) {
      const currentModal = state.modals.find((modal) => modal.name === name);
      currentModal.isOpen = false;
    },
    openModal(state, name) {
      const currentModal = state.modals.find((modal) => modal.name === name);
      currentModal.isOpen = true;
    },
    openModalCallback(state, payload) {
      const defaultCallbacks = {
        onConfirm: () => {},
        onCancel: () => {},
      };
      const index = state.modals.findIndex(
        (modal) => modal.name === payload.name
      );

      state.modals[index] = {
        ...state.modals[index],
        ...defaultCallbacks,
        ...payload,
        isOpen: true,
      };
    },
  },
};
