import dashboardDoctorRoutes from "@/router/dashboard/doctor";
import dashboardDietitianRoutes from "@/router/dashboard/dietitian";
import dashboardAdminRoutes from "@/router/dashboard/admin";
import dashboardSuperAdminRoutes from "@/router/dashboard/superAdmin";
import dashboardCommonRoutes from "@/router/dashboard/common";
import auth from "@/middleware/auth";
import hasRoles from "@/middleware/hasRoles";
import isUserNotBlocked from "@/middleware/isUserNotBlocked";
import checkIsVideoInWindow from "@/middleware/checkIsVideoInWindow";
import checkIsVideoMounted from "@/middleware/checkIsVideoMounted";
import isActivatedUser from "@/middleware/isActivatedUser";

export default {
  path: "/dashboard",
  meta: {
    middlewares: [
      auth,
      hasRoles,
      isUserNotBlocked,
      checkIsVideoInWindow,
      checkIsVideoMounted,
    ],
  },
  component: () => import(/* webpackChunkName: "user" */ "@/layouts/User.vue"),
  children: [
    {
      path: "",
      name: "Dashboard",
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ "@/views/User/Dashboard.vue"
        ),
    },
    {
      path: "referral",
      meta: {
        excludeMiddlewares: [isUserNotBlocked.name],
        extraMiddlewares: [isActivatedUser],
        roles: ["doctor", "admin", "super-clinic-admin"],
      },
      children: [
        {
          path: ":id",
          name: "DoctorReferral",
          props: (route) => ({ id: route.params.id * 1 }),
          component: () =>
            import(
              /* webpackChunkName: "referral" */ "@/views/Doctor/Referral.vue"
            ),
        },
        {
          path: ":id/results",
          name: "ReferralResults",
          props: (route) => ({ id: route.params.id * 1 }),
          component: () =>
            import(
              /* webpackChunkName: "referral-results" */ "@/views/User/Results.vue"
            ),
        },
      ],
    },
    dashboardDoctorRoutes,
    ...dashboardAdminRoutes,
    dashboardDietitianRoutes,
    dashboardSuperAdminRoutes,
    dashboardCommonRoutes,
  ],
};
