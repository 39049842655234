<template>
  <div
    class="fixed top-0 left-0 right-0 bottom-0 bg-background bg-opacity-70 z-[100] flex flex-col items-center justify-center"
  >
    <p class="text-main text-2xl">{{ text }}</p>
    <div class="w-1/3 h-2 bg-violet-200 rounded-full mt-10">
      <div
        :style="`width: ${progress}%`"
        class="h-full text-center text-xs text-white bg-fiolet rounded-full"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressLoader",
  props: {
    text: {
      type: String,
      default: "Generating results...",
    },
  },
  data() {
    return {
      progress: 0,
      progressInterval: null,
    };
  },
  methods: {
    startProgress() {
      this.progress = 0;
      this.progressInterval = setInterval(() => {
        this.progress += 5;
        if (this.progress >= 100) {
          clearInterval(this.progressInterval);
          this.startProgress();
        }
      }, 1000);
    },
  },
  mounted() {
    this.startProgress();
  },
  unmounted() {
    clearInterval(this.progressInterval);
  },
};
</script>
