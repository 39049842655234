import http from "@/utils/axios";
import {
  deleteNestedKeyAndClearEmpty,
  getValueByPath,
} from "@/utils/objectManipultaion";

export default {
  namespaced: true,
  state: {
    planId: null,
    patient: null,
    selectedPlan: null,
    doctorDescription: null,
    testResults: null,
    healthMetrics: null,
    generalSurvey: null,
    weightLossSurvey: null,
    weekPlans: [],
    nutritionistNote: "",
    trainingNote: "",
    isLoading: true,
    errors: {},
  },
  getters: {
    getParentData: (state) => state.patient,
    getSelectedPlan: (state) => state.selectedPlan,
    getDoctorDescription: (state) => state.doctorDescription,
    getTestResults: (state) => state.testResults,
    getHealthMetrics: (state) => state.healthMetrics,
    getGeneralSurvey: (state) => state.generalSurvey,
    getWeightLossSurvey: (state) => state.weightLossSurvey,
    getWeekPlans: (state) => state.weekPlans,
    getNutritionistNote: (state) => state.nutritionistNote,
    getTrainingNote: (state) => state.trainingNote,
    isLoading: (state) => state.isLoading,
    getErrorsFor: (state) => (errorKey) => {
      return getValueByPath(state.errors, errorKey);
    },
    getErrors: (state) => state.errors,
  },
  mutations: {
    setPlanId(state, id) {
      state.planId = id;
    },
    setPatientData(state, patient) {
      state.patient = patient;
    },
    setSelectedPlan(state, selectedPlan) {
      state.selectedPlan = selectedPlan;
    },
    setDoctorDescription(state, doctorDescription) {
      state.doctorDescription = doctorDescription;
    },
    setTestResults(state, testResults) {
      state.testResults = testResults;
    },
    setHealthMetrics(state, healthMetrics) {
      state.healthMetrics = healthMetrics;
    },
    setGeneralSurvey(state, generalSurvey) {
      state.generalSurvey = generalSurvey;
    },
    setWeightLossSurvey(state, weightLossSurvey) {
      state.weightLossSurvey = weightLossSurvey;
    },
    setWeekPlans(state, weekPlans) {
      state.weekPlans = weekPlans;
    },
    updateWeekPlanDayMeal(state, { dayIndex, mealIndex, data }) {
      Object.assign(state.weekPlans[dayIndex].meals[mealIndex], data);

      state.weekPlans[dayIndex].total_calories = state.weekPlans[
        dayIndex
      ].meals.reduce((prevSum, { total_calories }) => {
        return prevSum + total_calories * 1;
      }, 0);
    },
    setNutritionistNote(state, nutritionistNote) {
      state.nutritionistNote = nutritionistNote;
    },
    setTrainingNote(state, trainingNote) {
      state.trainingNote = trainingNote;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    clearErrorsFor(state, errorKey) {
      return deleteNestedKeyAndClearEmpty(state.errors, errorKey.split("."));
    },
  },
  actions: {
    async fetchDietaryPlan({ commit }, id) {
      commit("setIsLoading", true);
      commit("setPlanId", id);

      const [{ data: dataWeekPlan }, { data: dataWeekPlanDays }] =
        await Promise.all([
          http.get(`dietitian/dietary-week-plan/${id}`),
          http.get(`dietitian/dietary-week-plan/${id}/days`),
        ]);

      commit("setPatientData", dataWeekPlan.patient);
      commit("setSelectedPlan", dataWeekPlan.selected_plan);
      commit("setDoctorDescription", dataWeekPlan.doctor_description);
      commit("setTestResults", dataWeekPlan.test_results);
      commit("setHealthMetrics", dataWeekPlan.health_metrics);
      commit("setGeneralSurvey", dataWeekPlan.general_survey);
      commit("setWeightLossSurvey", dataWeekPlan.weight_loss_survey);
      commit(
        "setNutritionistNote",
        dataWeekPlan.descriptions.nutritionist_note
      );
      commit("setTrainingNote", dataWeekPlan.descriptions.training_note);
      commit("setWeekPlans", dataWeekPlanDays.plans);

      commit("setIsLoading", false);
    },
    async storePlan({ state }, isDone) {
      return http.put(`dietitian/dietary-week-plan/${state.planId}`, {
        week_plans: state.weekPlans,
        nutritionist_note: state.nutritionistNote,
        training_note: state.trainingNote,
        isDone,
      });
    },
    async getNextPlanId(_, { currentId, isPrevious }) {
      const { data } = await http.get(
        "dietitian/dietary-week-plan/next/" + currentId,
        {
          params: {
            is_previous: isPrevious,
          },
        }
      );
      return data;
    },
  },
};
