import auth from "@/middleware/auth";
import hasRoles from "@/middleware/hasRoles";
import isActivatedUser from "@/middleware/isActivatedUser";
import checkIsVideoInWindow from "@/middleware/checkIsVideoInWindow";
import checkIsVideoMounted from "@/middleware/checkIsVideoMounted";
import { ROLES } from "@/statements/roles";

export default {
  path: "",
  meta: {
    middlewares: [
      auth,
      hasRoles,
      isActivatedUser,
      checkIsVideoInWindow,
      checkIsVideoMounted,
    ],
    roles: [ROLES.DOCTOR, ROLES.DIETITIAN],
  },
  children: [
    {
      path: "patient-list",
      name: "DoctorPatientList",
      component: () =>
        import(
          /* webpackChunkName: "doctor-patient-list" */ "@/views/Doctor/PatientList.vue"
        ),
    },
  ],
};
