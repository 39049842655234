import axios from "axios";
import acceptLanguage from "accept-language";
import { notify } from "@kyvg/vue3-notification";

acceptLanguage.languages(["en", "pl", "ua"]);

let token = localStorage.getItem("authtoken");
let language =
  acceptLanguage.get(
    localStorage.getItem("language") || navigator.language.substring(0, 2)
  ) || "en";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 15000,
  useCredentials: true,
  headers: {
    Authorization: `Bearer ${token}`,
    "X-Requested-With": "XMLHttpRequest",
    "Accept-Language": language,
    "App-Name": process.env.VUE_APP_NAME,
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      let message = "messages.response.failure";

      if (error.response.status === 401) {
        message = "messages.response.unauthenticated";
      } else if (error.response.status === 403) {
        message = "messages.response.forbidden";
      } else if (error.response.status === 404) {
        message = "messages.response.not_found";
      } else if (error.response.status === 422) {
        message = "messages.response.validation";
      }

      notify({
        type: "danger",
        text: message,
        duration: 5000,
        ignoreDuplicates: true,
      });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
