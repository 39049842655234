import store from "@/store";

export default function isSmsVerified({ next, router }) {
  const isVerifiedSMS = store.getters["user/getCurrentUserVerified"];

  if (isVerifiedSMS) {
    return next();
  }

  return router.push({ name: "AuthCodeDoctor" });
}
