export default {
  computed: {
    isDev() {
      return process.env.VUE_APP_ENV === "development";
    },
    isProd() {
      return process.env.VUE_APP_ENV === "production";
    },
  },
};
