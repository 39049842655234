import auth from "@/middleware/auth";
import hasRoles from "@/middleware/hasRoles";
import { ROLES } from "@/statements/roles";

export default {
  path: "",
  meta: {
    middlewares: [auth, hasRoles],
    roles: [ROLES.DIETITIAN],
  },
  children: [
    {
      path: "dietary-plans",
      name: "DietaryPlans",
      component: () =>
        import(
          /* webpackChunkName: "dietary-plans" */ "@/views/Dietitian/DietaryPlansList.vue"
        ),
    },
    {
      path: "prepare/dietary-plan/:id",
      name: "PrepareDietaryPlan",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "prepare-dietary-plan" */ "@/views/Dietitian/PrepareDietaryPlan.vue"
        ),
    },
  ],
};
