import http from "@/utils/axios";

export default {
  namespaced: true,
  state: {
    diseases: null,
  },
  getters: {
    getAllDiseases: (state) => state.diseases,
    getDiseaseById: (state) => (id) =>
      state.diseases?.find((disease) => disease.id === id),
  },
  mutations: {
    setAllDiseases(state, diseases) {
      state.diseases = diseases;
    },
    addDynamicDisease(state, disease) {
      state.diseases.push(disease);
    },
  },
  actions: {
    async fetchDiseases({ commit }, doctorId = null) {
      const response = await http.get("/doctor/diseases", {
        params: {
          doctor_id: doctorId,
        },
      });
      const diseases = response.data.data;
      commit("setAllDiseases", diseases);
    },
  },
};
