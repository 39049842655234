import http from "@/utils/axios";

export default {
  namespaced: true,
  state: {
    docLanguages: null,
  },
  getters: {
    getAllDocLanguages: (state) => state.docLanguages,
    getDocLangById: (state) => (id) =>
      state.docLanguages.find((docLang) => docLang.id === id),
  },
  mutations: {
    setAllDocLanguages(state, docLanguages) {
      state.docLanguages = docLanguages;
    },
    addDynamicLanguage(state, language) {
      state.docLanguages.push(language);
    },
  },
  actions: {
    async fetchDocLanguages({ commit }, doctorId = null) {
      const response = await http.get("/doctor/languages", {
        params: {
          doctor_id: doctorId,
        },
      });
      const docLanguages = response.data.data;
      commit("setAllDocLanguages", docLanguages);
    },
  },
};
