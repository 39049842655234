<template>
  <router-view
    v-if="!isLoadingUser && !isLoadingFeatures"
    v-slot="{ Component }"
  >
    <transition appear mode="out-in" name="fade">
      <component :is="Component" />
    </transition>
  </router-view>
  <Notification />
  <Loading
    :active="isLoadingUser || isLoadingFeatures"
    :can-cancel="false"
    :is-full-page="true"
    :opacity="0"
    color="#0C1E67"
    loader="dots"
  />

  <Loading
    :active="isLoadingContent || isFullPageLoader"
    :can-cancel="false"
    :is-full-page="true"
    :opacity="0.7"
    color="#0C1E67"
    loader="dots"
  />

  <Teleport to="body">
    <ProgressLoader v-if="isGenerating" />
    <ProgressLoader v-if="isSaving" text="Saving..." />
  </Teleport>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Notification from "@/layouts/Notification";
import Loading from "vue-loading-overlay";
import ProgressLoader from "@/components/Core/ProgressLoader.vue";

export default {
  name: "App",
  components: {
    ProgressLoader,
    Notification,
    Loading,
  },

  computed: {
    ...mapGetters({
      isLoadingFeatures: "features/isLoadingFeatures",
      isLoadingUser: "loader/getUserLoaderStatus",
      isLoadingContent: "loader/getContentLoaderStatus",
      isFullPageLoader: "loader/getFullPageLoader",
      isGenerating: "loader/getGenerateLoader",
      isSaving: "loader/getSavingLoader",
    }),
  },
  methods: {
    ...mapActions({
      initCurrentLanguage: "language/initCurrentLanguage",
      fetchFeatures: "features/fetchFeatures",
    }),
  },
  created() {
    this.initCurrentLanguage();
    this.fetchFeatures();
  },
};
</script>
