import store from "@/store";

export default function isUserNotBlocked({ next, router }) {
  let userStatus = store.getters["user/getCurrentUserStatus"];

  if (userStatus !== "blocked") {
    return next();
  }

  localStorage.removeItem("authtoken");
  return router.push({ name: "Blocked" });
}
