import store from "@/store";

export default function hasRoles({ next, router, to }) {
  let currentUserRole = store.getters["user/getCurrentUserRole"];

  if (!to.meta.roles || to.meta.roles?.includes(currentUserRole)) {
    return next();
  }

  return router.push({ name: "ErrorForbidden" });
}
