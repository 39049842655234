export default {
  path: "/",
  component: () =>
    import(/* webpackChunkName: "guest" */ "@/layouts/Guest.vue"),
  children: [
    {
      path: "",
      name: "Home",
      redirect: { name: "LoginDoctor" },
    },
    {
      path: "error",
      name: "Error",
      component: () =>
        import(/* webpackChunkName: "error" */ "@/views/Error.vue"),
    },
    {
      path: "error/403",
      name: "ErrorForbidden",
      component: () =>
        import(
          /* webpackChunkName: "error-forbidden" */ "@/views/ErrorForbidden.vue"
        ),
    },
    {
      path: "blocked",
      name: "Blocked",
      component: () =>
        import(/* webpackChunkName: "blocked" */ "@/views/Blocked.vue"),
    },
    {
      path: "/:catchAll(.*)",
      component: () =>
        import(/* webpackChunkName: "not-found" */ "@/views/NotFound.vue"),
    },
  ],
};
