/* eslint-disable prettier/prettier */
export default {
  account: {
    setting: "Налаштування облікового запису",
    default_price_list: "Стандартний цінник на лабораторні обстеження",
    pwz: "pwz",
    personal_data: "Персональні дані",
    email: "Електронна пошта",
    password: "Пароль",
    update_data: "Відредагуйте свої дані",
    change_password: "Змініть свій пароль",
    blocked: " Обліковий запис заблоковано",
    contact_administrator: "Зверніться до адміністратора",
  },

  action: {
    change: "Змінити",
    see_details: "Побачити деталі",
    see_doctor: "Побачити лікаря",
    see_referral: "Побачити направлення",
    go_back: "Повернутися",
    back: "back",
    skip: "Skip",
    next: "next",
    save_changes: "Зберегти зміни",
    choose: "Вибрати",
    selected: "selected",
    continue: "Продовжувати",
    save: "Зберегти",
    clear: "Очистити",
    return: "Повернутися",
    filter: "Відфільтрувати",
    withdraw_funds: "Виплатити кошти",
    edit: "Редагувати",
    download: "Завантажити",
    expand: "Розгорнути",
    collapse: "Колапс",
    add: "Додати",
    add_patient: "Add patient",
    search: "Пошук",
    cancel: "Скасувати",
    show_more: "Більше",
    save_and: "save_and",
    exit: "exit",
    close: "Close",
  },

  search: {
    what_kind_of_visit_are_you_looking_for:
      "What kind of visit are you looking for?",
  },

  admin: {
    cities: {
      all: "Всі",
    },
    message: {
      title: "Назва",
      message: "Зміст повідомлення",
      send: "Надіслати повідомлення",
      choose_doctors: "Виберіть, кому ви хочете надіслати повідомлення",
      sent: "Повідомлення надіслано до",
      recipients: "одержувача/ів",
      recipients_number: "Кількість одержувачів",
    },

    doctor: {
      create: "Додати лікаря",
      doctor_data: "Дані лікаря",
      company_data: "Дані фірми",
      address: "Адреса",
      account_number: "Номер банківсього рахунку",
      first_name: "Ім'я",
      last_name: "Прізвище",
      pwz: "PWZ",
      main_medical_category: "Основна медична категорія",
      email: "Електронна пошта",
      registered_at: "Дата реєстрації",
      phone_number: "Номер телефону",
      company_name: "Назва фірми / клініки",
      nip: "NIP",
      street: "Вулиця",
      house_number: "Номер будинку",
      flat_number: "Номер приміщення",
      postal_code: "Почтовий індекс",
      city: "Місто",
      edit: "Редагувати лікаря",
      data_edit: "Редагувати дані",
      referrrals_list: "Список замовлень",
      send_message: "Надіслати повідомлення",
      status: "Статус",
      full_name: "Ім'я та прізвище",
      commission: "Оплата",
      company: "Фірма",
      back_to_list: "Повернутися до списку лікарів",
      search_doctor: "Знайти лікаря",
      account_inactice: "Обліковий запис неактивний",
      wait_for_activation:
        "Зачекайте до 24 годин, поки наші співробітники підтвердять Ваш обліковий запис",
      contact_info: "Зв'яжіться з нами через чат з 6:00 до 23:30",
      we_call_to_you: "Ми зателефонуємо Вам",
      check_account_settings:
        "Перевірте налаштування свого облікового запису, додайте дані для рахунку-фактури, якщо потрібно",
      go_to_settings: "Перейти до Налаштувань",
    },

    invoices: {
      invoices: "Рахунки-фактури",
      invoice_number: "Номер рахунку-фактури",
      amount: "Сума",
      doctor: "Лікар",
      account_number: "Номер рахунку",
      date_of_issue: "Дата виставлення",
      date_of_transfer: "Дата переказу",
      download_all: "Завантажити всі",
      download_invoice: "Завантажити рахунок-фактуру",
      new: "Нове",
      paid: "Оплачене",
      amount_to_pay: "Сума для оплати",
      select_month:
        "Виберіть місяць, за який потрібно завантажити рахунки-фактури",
      statuses: {
        new: "Нове",
        paid: "Оплачене",
        to_send: "Надіслати",
        all: "Все",
        sent: "Надіслано",
      },
      purchaser_name: "Замовник",
      order_number: "Номер замовлення",
      date_of_order: "Дата оформлення замовлення",
      date_execution: "Дата виконання",
      invoice_issued: "Виставлений",
      mark_as_issued: "Позначити як виставлений",
      mark_as_issued_confirm:
        "Підтвердьте, що Ви хочете змінити статус рахунку-фактури на виставлений",
      csv: "csv",
      status: "статус",
      select_file: "Виберіть файл",
      add_file: "Додати файл",
    },

    promotions: {
      name: "Code Name",
      type: "Code Type",
      description: "Code Description",
      stepper: {
        next: "Next",
        prev: "Back",
        finish: "Finish",
      },
      discount_per_order: "Discount on the entire order",
      discount_on_product: "Discount on every matching product",
      buy_to_receive: "Buy X to receive Y",
      newsletter_discount: "Newsletter discount",
      numeric: "Numeric",
      alphanumeric: "Alphanumeric",
      alphabetical: "Alphabetical",
      discount: "Discount Amount",
      discount_type: "Type of Discount",
      is_personal_codes: "Personal Codes",
      what_examination_are_you_looking_for:
        "What research are you looking for?",
      which_products_does_this_promotion_apply_to:
        "Which products does this promotion apply to?",
      select_all_examinations: "Select all tests",
      select_all_bundles: "Select all packages",
      examination_name: "Examination Name",
      bundle_name: "Package Name",
      limited_promotion: "Limited promotion",
      number_of_promotions_for_the_code: "Number of promotions for the code",
      infinitely_many_for_the_code: "Infinitely many for the code",
      number_of_promotions_for_the_user: "Number of promotions for the user",
      base_amount: "Base amount",
      duration_of_the_promotion: "Duration of the promotion",
      from: "From",
      to: "To",
      enter_the_code: "Enter the code",
      new_code: "New code",
    },

    doctor_card: {
      status: "Статус",
      referral_number: "Номер направлення",
      total_price: "Ціна",
      commission: "Оплата",
      patient: "Пацієнт",
      date_of_issue: "Дата виставлення",
      date_of_examination: "Дата проведення обстеження",
      city: "Місто",
      street: "Вулиця",
      postal_code: "Поштовий індекс",
    },
  },

  auth: {
    complete_your_doctor_profile: "complete_your_doctor_profile",
    go_to_the_main_dashboard: "go_to_the_main_dashboard",
    create_an_account: "Створити обліковий запис",
    select_account_type: "Select Account Type",
    create_account: "create_account",
    enter_code: "Введіть реєстраційний код",
    enter_code_below: " Введіть цей код нижче",
    enter_new_password: "Введіть новий пароль для свого облікового запису",
    email: "Адреса електронної пошти",
    first_name: "Ім'я ",
    go_back_to_login: "Поверніться до входу в систему",
    have_account: "У Вас уже є обліковий запис?",
    last_name: "Прізвище",
    full_name: "Ім'я та прізвище",
    logout: "Вийти",
    not_have_account: "У Вас немає облікового запису?",
    not_receive_code: "Не отримали код",
    not_remember_password: "Не пам'ятаєте пароль?",
    password: "Пароль",
    password_confirm: "Повторіть пароль",
    password_remind: "Нагадати пароль",
    password_reset: "Скинути пароль",
    phone: "Номер телефону",
    pwz: "№ ліц. лікаря",
    register: "Зареєструватися",
    register_go: "Go to registration",
    not_member: "Not a member yet?",
    join_us: "Join us now!",
    reset: "Скидання пароля",
    send_reset_link:
      "Ми надішлемо вам посилання для відновлення пароля на вказану адресу електронної пошти",
    sent_reset_link: "Ми надіслали вам SMS з кодом підтвердження на номер",
    sent_reset_link_email:
      "Ми надіслали вам електронного листа з посиланням для відновлення пароля.",
    sent_reset_link_error:
      "Посилання надіслано, зачекайте, щоб отримати нове посилання.",
    reset_link_expired:
      "Термін дії вашого посилання для скидання пароля закінчився",
    sign_in: "Увійти",
    sign_up: "Зареєструватися",
    sms: "Код SMS",
    specialist_panel: "До панелі спеціаліста",
    patient_panel: "До панелі пацієнта",
    verify: "Перевірити",
    pay_for_examinations: "оплатити за обстеження",
    enter_email_and_password: "Введіть адресу електронної пошти та пароль,",
    to_create_account: "аби створити обліковий запис на порталі",
    enter_phone_number: "Впишіть свій номер телефону",
    enter_pesel: "Введіть свій Pesel",
    login_by_pesel: "Зареєструйтеся, використовуючи свій Pesel",
    login_by_phone: "Зареєструйтеся, використовуючи свій номер телефону",
    identity_card: "Номер посвідчення особи",
    pesel: "Номер Pesel",
    current_password: "Поточний пароль",
    set_password: "Встановіть пароль",
    salary: "Оплата",
    patient_account: "на рахунок пацієнта",
    country_code: "Код країни",
    phone_number: "Телефонний номер",
    checkboxes: {
      information_obligation: "Інформаційному зобов'язанні",
      agree: "Заявляю, що я прочитав і приймаю",
      agree_marketing:
        "Я даю згоду на обробку моїх персональних даних, а саме адреси електронної пошти, з метою надсилання інформаційного бюлетеня в електронному",
      agree_contact:
        "Я погоджуюсь, що GetLabTest laboratoria Sp. z o.o. з місцезнаходженням у Варшаві, вул.",
      agree_contact_expand:
        " Стенпінська 22/30, 00-739 Варшава, може зв'язатися зі мною з метою обговорення умов договору за допомогою телекомунікаційних кінцевих пристроїв та систем автоматичного виклику відповідно до Закону від 16 липня 2004 року. - Закон про телекомунікації, тобто, зокрема, за допомогою мобільного телефону",
      terms_of_service: "Умови надання послуг",
      service: "послуга",
      and: "та",
      privacy_policy: "Політику конфіденційності",
      agree_processing_my_personal_data:
        "Я даю згоду на обробку моїх персональних даних, зазначених у вищевказаній формі, з метою створення облікового запису.",
      agree_processing_my_personal_data_expand:
        " Адміністратором даних є {company_name} {address}. Заявляю, що я був проінформований про свої права, з якими я можу ознайомитися в",
      platform: "platform",
      page_name: "GetLabTest.com",
      terms: "Умови",
    },
    commission_options: {
      d15p0: "15% для лікаря, 0% для пацієнта",
      d10p5: "10% для лікаря, 5% для пацієнта",
      d5p10: "5% для лікаря, 10% для пацієнта",
      d0p15: "0% для лікаря, 15% для пацієнта",
      other: "Інший",
    },
    birth_date: "Дата народження",
    day: "День",
    month: "Місяць",
    year: "Рік",
    no_pesel: "Немає номера PESEL",
    enter_pesel_number: "Введіть свій номер PESEL",
    enter_birth_date: "Введіть свою дату народження",
    gender: {
      title: "Cекс",
      m: "Людина",
      f: "жінка",
    },
    choose_appropriate_account_category:
      "Choose the appropriate account category for registration",
    a_doctor_or_specialist: "a_doctor_or_specialist",
    individual_account_for_one_doctor: "individual_account_for_one_doctor",
    medical_entity: "medical_entity",
    the_account_is_ideal_for_both_clinics_and_small_offices:
      "the_account_is_ideal_for_both_clinics_and_small_offices",
    soon: "Soon",
    registration_process: "registration_process",
    step: "step",
    your_data: "your_data",
    professional_details_and_account_setup:
      "Professional Details and Account Setup",
    enter_your_professional_information:
      "Enter your professional information and credentials to create your profile.",
    sms_veryfication: "sms_veryfication",
    collection_point: "collection_point",
    patient_hours: "Patient hours",
    billings_information: "billings_information",
    doctor_services: "Service",
    define_your_services: "Define Your Services",
    personalize_your_service_portfolio:
      "Personalize your service portfolio to reflect your expertise. We have pre-populated your profile with the most popular services in your specialty for your convenience. Feel free to edit, delete, or add new services to match your specific practice and professional focus.",
    date: "date",
    availability_schedule_setup: "Availability Schedule Setup",
    set_your_weekly_availability:
      "Set your weekly availability to optimize appointment bookings and ensure efficient patient service. This step is essential for managing your time and enhancing patient accessibility.",
    hours_closed: "Close",
    select_meeting_interval:
      "In what time intervals would you like to schedule patient appointments?",
    edit_appoitment_hours: "Edit patient appointment hours.",
  },

  cart: {
    selected_examinations: "Вибране обстеження",
    no_examinations: "Ви ще не обрали жодного обстеження для направлення.",
    nurse_service: "Медсестринські послуги",
    covid_service: "Медсестринські послуги COVID",
    total: "Разом",
    your_commission: "Твоя оплата",
    your_discount: "Знижка",
    issue_referral: "Виписати направлення",
    edit_referral: "Відредагуйте направлення",
    edit_referral_warning:
      "Редагування цього направлення призведе до того, що з’явиться нове перенаправлення, а поточне направлення буде скасовано.",
    pay_referral: "Заплатити",
    details: "Деталі",
    selected_collection_point: "Вибраний пункт забору",
    search_placeholder: "search_placeholder",
  },

  collection_facility: {
    collection_facility_title: "Виберіть пункт збору",
    collection_facility_text:
      "Визначіть, до якого пункту ви хочете бачити цінник із зазначенням вартості лабораторних досліджень. Ви зможете змінити його в будь-який час у своєму профілі.",
    search_placeholder: "Впишіть місто або поштовий індекс",
    current_location_error:
      "Ви не дозволили браузеру визначати місце Вашого знаходження. Спробуйте ще раз.",
    hide_list: "Приховати список",
    show_list: "Показати список",
    show_filters: "Показати фільтри",
    hide_filters: "Приховати фільтри",
    show_on_map: "Показати на карті",
    set_the_route: "Визначте маршрут",
    additional_options: "additional_options",
    selected_collection_points: "selected_collection_points",
  },

  dashboard: {
    inaccessible_facility:
      "Ваш пункт збору є неактивним або ви не можете проходити там дослідження.",
    inaccessible_facility_btn: "Змінити пункт збору",
    commission_info:
      "Винагорода — це сума сум, що підлягають оплаті за виконані замовлення",
  },

  doctor_status: {
    blocked: "Заблокований",
    active: "Активний",
    inactive: "Неактивний",
    all: "Всі",
  },

  empty_list: {
    no_data: "no_data",
    referrals: "Список направлень порожній.",
    doctors: "Список лікарів порожній.",
    patients: "Список пацієнтів порожній.",
    invoices: "Список рахунків-фактур порожній.",
  },

  error: {
    accept_data_processing: {
      accepted: "Згода є обов'язковою.",
    },
    agree_contact: {
      accepted: "Згода є обов'язковою.",
    },
    gender: {
      required: "Укажіть стать.",
    },
    accept_terms: {
      accepted: "Згода є обов'язковою.",
    },
    accept_marketing: {
      accepted: "Згода є обов'язковою.",
    },
    code: {
      required: "Потрібен SMS-код.",
      incorrect: "Наданий код є недійсним.",
    },
    email: {
      already_taken: "Надана адреса електронної пошти була вже використана.",
      credentials_not_match: "Облікові дані неправильні.",
      email: "Електронна адреса має бути правильною.",
      required: "Електронна адреса є обов'язковою.",
      selected_invalid: "Вказаної електронної адреси немає в базі даних.",
    },
    pesel: {
      required: "Номер PESEL є обов'язковим.",
      already_taken: "Поданий номер PESEL вже використано.",
      digits: "Номер PESEL повинен мати 11 цифр.",
      invalid: "Неправильний номер PESEL.",
      required_if_empty: {
        birth_date: "Необхідно вказати номер PESEL або дату народження.",
      },
      required_when_not_present: {
        phone_number: "Необхідний номер телефону або Pesel.",
      },
      incorrect: "Ми не можемо знайти користувача з даним pesel.",
    },
    birth_date: {
      required: "Необхідно вказати дату народження.",
      required_if_empty: {
        pesel: "Необхідно вказати номер PESEL або дату народження.",
      },
      must_be_date_after_or_equal:
        "Дата народження має бути більшою або дорівнювати 01.01.1900.",
      must_be_a_date_before: "Не можна вибрати цю дату народження.",
      invalid_date: "Дата народження є неправильною датою.",
      incorrect: "error.bith_date.incorrect",
    },
    first_name: {
      required: "Ім'я є обов'язковим.",
    },
    last_name: {
      required: "Прізвище є обов'язковим.",
    },
    main_medical_category_id: {
      required: "Будь ласка, виберіть категорію.",
    },
    current_password: {
      required: "Поточний пароль є необхідним.",
      at_least_characters: "Поточний пароль має містити не менше 8 символів.",
      invalid_format:
        "Поточний пароль має містити принаймні одну малу літеру, одну велику літеру та одну цифру.",
      incorrect: "Поточний пароль неправильний.",
    },
    password: {
      required: "Пароль є необхідним.",
      at_least_characters: "Пароль має містити не менше 8 символів.",
      invalid_format:
        "Пароль має містити принаймні одну малу літеру, одну велику літеру та одну цифру.",
    },
    password_confirm: {
      required: "Потрібне підтвердження пароля.",
      must_match: "Пароль і підтвердження пароля мають бути однаковими.",
    },
    phone_number: {
      already_taken: "Наданий номер телефону вже використано.",
      required: "Необхідно вказати номер телефону.",
      digits: "Номер телефону мусить мати 9 цифр.",
      incorrect: "Номер телефону недійсний.",
      accepted: "Номер телефону недійсний.",
      choose_country: "вибрати країну",
      required_when_not_present: {
        pesel: "Необхідний номер телефону або Pesel.",
      },
    },
    national_number: {
      required: "Необхідно вказати номер телефону.",
    },
    phone_is_valid: {
      accepted: "Недійсний номер телефону",
    },
    pwz: {
      required: "Pwz є необхідним.",
    },
    company_name: {
      required: "Необхідно вказати назву фірми / клініки.",
    },
    nip: {
      required: "Nip є необхідним.",
      digits: "Nip мусить мати 10 цифр.",
    },
    street: {
      required: "Необхідно вказати вулицю.",
    },
    house_number: {
      required: "Необхідно вказати номер будинку.",
    },
    postal_code: {
      required: "Необхідно вказати поштовий індекс.",
    },
    city: {
      required: "Необхідно вказати місто.",
    },
    account_number: {
      required: "Необхідно вказати номер банківського рахунку.",
      invalid: "Номер банківського рахунку неправильний.",
    },
    agree1: {
      accepted: "Згода є обов'язковою.",
    },
    identity_card: {
      required: "Необхідно вказати номер посвідчення особи.",
    },
    title: {
      required: "Заголовок є обов'язковим.",
    },
    message: {
      required: "Зміст повідомлення є обов'язковим.",
    },
    commission_option: {
      required: "Опція оплати є обов'язковою.",
      selected_invalid: "Опція оплати має бути однією із вищенаведених.",
    },
    is_verify: "Обліковий запис із вказаним номером телефону вже існує.",
    name: {
      required: "Назва є обов'язковим.",
    },
    promotions: {
      name: {
        required: "Code name is required.",
      },
      description: {
        required: "Code description is required.",
      },
      type: {
        required: "Code type is required.",
      },
      discount: {
        required: "Discount amount is required.",
        maxValue: "Discount amount cannot exceed 100%.",
        minValue: "Discount amount cannot be less than 0.",
      },
      type_discount: {
        required: "Discount type is required.",
      },
      use_limit_per_user: {
        required: "Number of promotions for the user is required.",
      },
      minimum_order_amount: {
        required: "Base amount is required.",
      },
      start_date: {
        beforeEndDate: "Start date must be earlier than end date.",
      },
      code_save_types: {
        required: "Method of generating code is required.",
      },
      file: {
        required: "File import is required.",
      },
      code: {
        required: "Code is required.",
      },
      amount_codes: {
        required: "Number of codes is required.",
      },
      length_limit: {
        required: "Number of code characters is required.",
      },
      code_type: {
        required: "Code format is required.",
      },
      suffix: {
        required: "Code suffix is required.",
      },
      prefix: {
        required: "Code prefix is required.",
      },
      packages: {
        minLength: "The promotion must include at least one test or package.",
      },
    },
  },

  error_page: {
    ups: "Щось пішло не так. Зачекайте деякий час або спробуйте оновити сторінку.",
    refresh: "Оновити",
  },

  examinations: {
    package: "пакет",
    no_examinations: "Немає обстежень.",
    no_packages: "Немає пакетів.",
  },

  filters: {
    status: "Статус",
    referral_id: "Referral ID",
    referral_number: "Номер направлення",
    patient: "Пацієнт",
    doctor: "Лікар",
    price: "Ціна",
    commission: "Оплата",
    date_of_issue: "Дата видання",
    date: "Date",
    cost: "Вартість обстеження",
    company_name: "Назва клініки",
    patient_discount_percent: "Відсоток знижки",
    package: "package",
    salary: "salary",
    number_of_tests: "Number of tests",
  },

  patient_discount_percent: {
    zero: "0 %",
    five: "5 %",
    ten: "10 %",
    fifteen: "15 %",
    all: "Всі",
  },

  homepage: {
    doctor: "Лікар",
    patient: "Пацієнт",
    admin: "Адміністратор",
    cms: "CMS",
    shop: "Магазин",
    faq: "FAQ",
    about_us: "Про нас",
    contact: "Контакт",
    catalog_of_examinations: "Каталог досліджень",
    cart: "Кошик",
    my_referrals: "Мої доручення",
    discount_1: "До",
    discount_2: "На усі пакети досліджень",
    discount_3: "Перевірити",
    highest_quality_research:
      "Виконайте дослідження найвищої якості у всій Польщі",
    choose_from:
      "Виберіть з-понад 3500 досліджень, 68 категорій досліджень та пакетів.",
    join_special_actions:
      "Приєднайтеся до спеціальних акцій, користуйтеся знижками, створюйте власні пакети і використовуйте обліковий запис пацієнта з історією та аналізами результатів досліджень.",
    shortcuts: "Найпоширеніші",
    morphology: "Морфологія",
    glucose: "Глюкоза",
    cpr: "CRP",
    creatynine: "Креатинін",
    potassium: "Калій",
    iron: "Залізо",
    mercury_in_the_blood: "Ртуть у крові",
    collection_points_1: "Понад",
    collection_points_2: "пунктів забору",
    research_every_year_1: "Понад",
    research_every_year_2: "млн",
    research_every_year_3: "досліджень на рік",
    years_on_the_market_1: "Понад",
    years_on_the_market_2: "років",
    years_on_the_market_3: "на ринку",
    what_examination_are_you_looking_for: "Яке дослідження Ви шукаєте?",
    switch_roles: "Switch roles",
  },

  layouts: {
    issue_referral: "Видати направлення",
    referral_history: "Історія направлень",
    patient_list: "Список пацієнтів",
    main_page: "домашня сторінка",
    examintons_list: "Каталог досліджень",
    own_packages: "Власні пакети",
    tests_to_describe: "Tests to describe",
  },

  medical_categories: {
    allergist: "Алерголог",
    pediatric_allergist: "Дитячий алерголог",
    andrologist: "Андролог",
    anesthesiologist: "Анестезіолог",
    angio_surgeon: "Ангіохірург",
    angiologist: "Ангіолог",
    audiologist: "Аудіолог",
    bariatra: "Баріатр",
    forensic_expert: "Судовий експерт",
    surgeon: "Хірург",
    pediatric_surgeon: "Дитячий хірург",
    thoracic_surgeon: "Хірург грудної клітки",
    vascular_surgeon: "Судинний хірург",
    plastic_surgeon: "Пластичний хірург",
    dental_surgeon: "Стоматологічний хірург",
    maxillofacial_surgeon: "Щелепно-лицевий хірург",
    dermatologist: "Дерматолог",
    pediatric_dermatologist: "Дитячий дерматолог",
    diabetologist: "Діабетолог",
    pediatric_diabetologist: "Дитячий діабетолог",
    diagnostic: "Діагностик",
    dietician: "Дієтолог",
    endocrinologist: "Ендокринолог",
    pediatric_endocrinologist: "Дитячий ендокринолог",
    physiatrist: "Фізіотерапевт",
    pediatric_physiatrist: "Дитячий фізіотерапевт",
    phlebologist: "Флеболог",
    gastrologist: "Гастроентеролог",
    pediatric_gastrologist: "Дитячий гастроентеролог",
    geneticist: "Генетик",
    geriatrician: "Геріатр",
    gynecologist: "Гінеколог",
    pediatric_gynecologist: "Дитячий гінеколог",
    hematologist: "Гематолог",
    pediatric_hematologist: "Дитячий гематолог",
    hepatologist: "Гепатолог",
    dental_hygienist: "Стоматологічна гігієністка",
    hypertensiologist: "Гіпертензіолог",
    histopathologist: "Гістопатолог",
    immunologist: "Імунолог",
    internist: "Терапевт",
    cardiac_surgeon: "Кардіохірург",
    cardiologist: "Кардіолог",
    pediatric_cardiologist: "Дитячий кардіолог",
    laryngologist: "Ларинголог",
    pediatric_laryngologist: "Дитячий ларинголог",
    infectious_diseases_physician: "Лікар-інфекціоніст",
    occupational_medicine_physician: "Лікар медицини праці",
    doctor_of_emergency_medicine: "Лікар невідкладної медицини",
    physician_of_medical_rehabilitation: "Лікар медичної реабілітації",
    family_doctor: "Сімейний лікар",
    sports_doctor: "Спортивний лікар",
    sports_medicine_physician: "Лікар спортивної медицини",
    a_doctor_in_the_process_of_specialization: "Лікар-резидент",
    doctor_without_specialization: "Лікар без спеціалізації",
    logopedist: "Логопед",
    nephrologist: "Нефролог",
    pediatric_nephrologist: "Дитячий нефролог",
    neonatologist: "Неонатолог",
    neurosurgeon: "Нейрохірург",
    pediatric_neurosurgeon: "Дитячий нейрохірург",
    neurologist: "Невролог",
    pediatric_neurologist: "Дитячий невролог",
    ophthalmologist: "Окуліст",
    pediatric_ophthalmologist: "Дитячий окуліст",
    oncologist: "Онколог",
    pediatric_oncologist: "Дитячий онколог",
    optometrist: "Оптометрист",
    orthodontist: "Ортодонт",
    orthopedist: "Ортопед",
    pediatric_orthopedist: "Дитячий ортопед",
    orthoptist: "Ортоптист",
    osteopath: "Остеопат",
    pathomorphologist: "Патоморфолог",
    pediatrician: "Педіатр",
    perinatologist: "Перинатолог",
    nurse: "Медсестра",
    obstetrician: "Акушер",
    midwife: "Акушерка",
    proctologist: "Проктолог",
    prosthetist: "Протезист",
    psychiatrist: "Психіатр",
    child_psychiatrist: "Дитячий психіатр",
    psychologist: "Психолог",
    child_psychologist: "Дитячий психолог",
    psycho_oncologist: "Психоонколог",
    psychotherapist: "Психотерапевт",
    psychotraumatologist: "Психотравматолог",
    pulmonologist: "Пульмонолог",
    pediatric_pulmonologist: "Дитячий пульмонолог",
    radiologist: "Радіолог",
    radiation_therapist: "Радіотерапевт",
    rheumatologist: "Ревматолог",
    pediatric_rheumatologist: "Дитячий ревматолог",
    sexologist: "Сексолог",
    specialist_in_aesthetic_medicine: "Спеціаліст з естетичної медицини",
    dentist: "Стоматолог",
    pediatric_dentist: "Дитячий стоматолог",
    transplantologist: "Трансплантолог",
    coach: "Тренер",
    ultrasonograph: "Ультрасонограф",
    urologist: "Уролог",
    pediatric_urologist: "Дитячий уролог",
    venereologist: "Венеролог",
    main_category: "Спеціалізація",
    list: "Спеціалізації",
    all: "Усі",
  },

  messages: {
    code: {
      resend: "Код надіслано знову.",
      resend_error: "Під час надсилання коду сталася помилка.",
    },
    edit: {
      success: "Зміни збережено.",
      failure: "Сталася помилка. Зміни не збережено.",
    },
    edit_amd_send_seferral: {
      success: "Зміни збережено та направлення відправлено повторно.",
    },
    save: {
      success: "Успішно збережено.",
      failure: "Сталася помилка. Зміни не збережено.",
    },
    sms: {
      success: "Sms надіслано повторно",
      failure: "Сталася помилка. Не вдалося надіслати Sms знову.",
    },
    send: {
      failure: "Сталася помилка. Не вдалося надіслати повідомлення.",
    },
    payment_token: {
      failure:
        "Сталася помилка. Будь ласка, спробуйте ще раз або зверніться до адміністратора.",
    },
    permission: {
      failure: "Це неможливо зробити.",
      forbidden:
        "Обліковий запис неактивний, зв'яжіться з офісом обслуговування клієнтів.",
    },
    download: {
      failure: "Під час завантаження файлу сталася помилка.",
      no_invoice: "Немає рахунків-фактур за вибраний місяць.",
      no_files: "Завантажень не знайдено.",
    },
  },

  not_found_page: {
    error: "Помилка",
    not_found: "Сторінку не знайдено",
    go_back: "Повернутися на головну сторінку",
  },

  pages: {
    account: "Налаштування облікового запису ",
    history_orders: "Історія направлень",
    my_settlements: "Мої розрахунки",
    doctors: "Лікарі",
    referrals: "Направлення",
    invoices: "Рахунки-фактури",
    your_referrals: "Ваші направлення",
    clientinvoices: "Рахунки-фактури клієнтів",
    raports: "Raporty",
    patient_card: "Patient's card",
    appointments: "Appointments",
    promotions: "Promotions",
  },

  patients: {
    edit_patient: "Редагувати вибраного пацієнта",
    edit: "Редагувати пацієнта",
    no_patients: "У вас ще немає пацієнтів.",
    patient_in_system_assign_to_another_doc: "Пацієнт вже в нашій системі",
    request_access_to_historical_test_results:
      "З метою проведення кращої діагностики, попросіть доступ до попередніх результатів досліджень",
    search_placeholder: "search_placeholder",
  },

  referral_status: {
    doctor: {
      issued: "Виставлене",
      paid: "Оплачене",
      canceled: "Анульоване",
      completed: "Реалізоване",
      all: "Всі",
    },
    admin: {
      issued: "Виставлене",
      paid: "Оплачене",
      canceled: "Анульоване",
      completed: "Реалізоване",
      all: "Всі",
      visit_data_missing: "У замовленні відсутня обрана дата візиту",
    },
    patient: {
      issued: "Нове",
      paid: "Оплачене",
      canceled: "Анульоване",
      completed: "Реалізоване",
    },
  },

  empty_services: {
    packages: "Немає пакетів",
    examinations: "Немає досліджень",
    search: "Фраза, яку Ви шукаєте, не знайдена",
  },

  own_packages: {
    examination_details: "Деталі дослідження",
    package_details: "Деталі пакету",
    add_to_own_package: "Додати до власного пакету",
    create_first_own_package:
      "Створіть свій перший власний пакет, ввівши його назву нижче.",
    edit_own_package: "Редагувати назву власного пакету",
    my_bundles: "my_bundles",
    your_own_packages: "Ваші власні пакети",
    add_to_new_own_package: "Додати до нового власного пакету",
    create_own_package: "Створіть власний пакет",
    create_your_own_package: "create_your_own_package",
    create_own_examination_package: "Створіть власний пакет досліджень",
    other_examinations: "Інші дослідження",
    all_examinations: "all_examinations",
    examination_packages: "Пакети досліджень",
    own_packages: "Власні пакети",
    create_first_own_examination_package:
      "Створіть свій перший пакет досліджень",
    with_our_own_packages: "Завдяки власним пакетам",
    faster_access:
      "Ви матимете швидший доступ до досліджень, які часто шукаєте",
    shorter_working_time: "Ви скоротите час своєї роботи і видачі доручень",
    edit_package_name: "Редагувати назву пакету",
    delete_package: "Видалити пакет",
    remove_package: "Видалити пакет",
    add_to_cart: "Додати у кошик",
    add_to_cart_selected: "Додати вибрані дослідження",
    add_new_own_package: "Додати новий власний пакет",
    update_own_package: "Редагувати власний пакет",
    name_own_package: "Назва власного пакету",
    delete_new_own_package: "Видалити власний пакет",
    delete_new_own_package_confirm: "Ви впевнені, що хочете видалити пакет",
    examinations: "дослідження",
    examinations_counter: "дослідження",
    add_examinations_to_package: "Додайте в пакет ще один дослідження",
    no_examinations_in_package_1: "no_examinations_in_package_1",
    no_examinations_in_package_2: "no_examinations_in_package_2",
    no_examinations_in_package_3: "no_examinations_in_package_3",
    no_examinations_in_package_4: "no_examinations_in_package_4",
    no_examinations_in_package_5: "no_examinations_in_package_5",
  },

  referrals: {
    collection_points: "collection_points",
    check_collection_point: "check_collection_point",
    tests_results: "tests_results",
    tests_results_will_be_available: "tests_results_will_be_available",
    name_and_surname: "name_and_surname",
    receive_VAT_invoice: "Я хочу отримати рахунок-фактуру з ПДВ",
    to_receive_an_invoice:
      "Щоб отримати рахунок, зв'яжіться з нами електронною поштою:",
    enter_study_name: "Впишіть назву дослідження",
    referral: "Направлення",
    number_short: "номер",
    examinations: "дослідження",
    date_of_issue: "дата виставлення",
    date_of_test: "дата проведення обстежень",
    exact_price: "Точну ціну ви дізнаєтеся, вибравши пункт збору",
    doctor: "лікар",
    number: "Номер направлення {number}",
    issued_on: "Виставлено",
    see_results: "Переглянути результати досліджень",
    commissioned_examinations: "Замовлені дослідження",
    recommended_referrals: "recommended_referrals",
    patient: "Пацієнт",
    phone_number: "Номер телефону",
    pesel: "Номер Pesel",
    payment: "Платіж",
    commission: "Ваша оплата",
    add_or_choose_patient:
      "Заповніть дані нового пацієнта або знайдіть у списку своїх пацієнтів",
    issue_referrals: "Випишіть направлення",
    patient_list: "Список пацієнтів",
    my_patient_list: "Список моїх пацієнтів",
    choose_patient: "Виберіть пацієнта зі списку",
    full_name: "Ім'я та прізвище",
    search_patient: "Введіть ім'я, прізвище, номер PESEL або номер телефону",
    search_referral_patient: "Введіть ім'я або прізвище",
    send_sms_again: "Надішліть SMS ще раз",
    edit_issued_referral: "Редагувати замовлені дослідження",
    issued_referral_text_part1:
      "Ви видали направлення. Ми надіслали пацієнту SMS з інструкцією, як виконати замовлення.",
    issued_referral_text_part2:
      "Ви отримаєте оплату, коли пацієнт оплатить направлення.",
    referral_for_examination: "Замовлення лабораторних досліджень",
    referral_for_examination_text:
      "Роздруковане замовлення може бути використане вашим пацієнтом безпосередньо в пункті прийому лабораторії GetLabTest. Без оплати на місці. Оплата тільки ОНЛАЙН.",
    print_referral: "Роздрукуйте направлення",
    collection_facilities: "Пункт збору",
    collection_facilities_text:
      "Перевірте, в якому пункті збору ваш пацієнт може оформити направлення",
    see_collection_facilities: "Перегляньте пункт збору",
    examination_findings: "Результати досліджень",
    examination_findings_text:
      "Результати досліджень будуть доступні після завершення направлення",
    unavailable: "Недоступне",
    waiting_for_payment: "Очікування на платіж",
    waiting_for_commissions: "Очікування на оплату",
    price_online_payment: "Ціна за онлайн-оплату на GetLabTest Online.pl",
    price_facility_payment: "Ціна при оплаті в пункті прийому GetLabTest",
    commission_after_purchase:
      "Оплата буде розрахована після того, як пацієнт придбає направлення",
    no_referrals: "У вас немає виписаних направлень.",
    issue_referral: "Виписати направлення на обстеження",
    popular_examinations: "Обстеження, яке найчастіше вибирають",
    package: "пакет",
    added: "Додано",
    number_of_referrals: "Кількість направлень",
    average_number_of_referrals: "Середня кількість направлень",
    average_price_of_referral: "Середня ціна направлення",
    top_doctors: "Лікарі, які виписали направлення на найбільшу суму:",
    your_facility: "Твій пунк збору",
    choose_examinations: "Виберіть обстеження",
    additionally_examinations:
      "Якщо хочете, ви можете додатково пройти інші обстеження",
    ordered_examinations: "Обстеження, на які було направлено",
    change: "Змініть дані та відправте направлення ще раз",

    tabs: {
      packages: "Пакети",
      examinations: "Обстеження",
    },

    table: {
      id: "Id направлення",
      date: "Дата",
      full_name: "Ім'я та прізвище",
      identity_card: "Номер посвідчення особи",
      status: "Статус",
      phone: "Телефон",
      number_of_examinations: "Кількість обстежень",
      commission: "Оплата",
      show: "Переглянути направлення",
      patient_card: "Patient's card",
      code: "Поштовий індекс",
      city: "Місто",
      street: "Вулиця",
      price: "Price",
      cost: "Вартість обстеження",
      pesel: "Номер Pesel",
      patient_discount_percent: "Відсоток знижки",
      patient_discount: "Знижка",
      upcoming_visits: "Upcoming visits",
    },
  },

  results: {
    download_pdf: "Завантажити результати у форматі PDF",
    back_to_referral: "Повернутися до направлення",
    choose_examination: "Виберіть дослідження, щоб переглянути результати",
  },

  select_time: {
    current_month: "Поточний місяць",
    previous_month: "Попередній місяць",
    last_90_days: "Останні 90 днів",
    last_6_months: "Останні 6 місяців",
    current_year: "Поточний рік",
    selected_from: "Виберіть із календаря",
    select_period: "select_period",
  },

  titles: {
    your_finances: "Ваші фінанси",
    your_stats: "Ваша статистика",
    stats: "Статистика",
    top_doctors: "Топ лікарів",
  },

  your_finances: {
    to_pay: "Оплатити",
  },

  your_referrals: {
    see_results: "Дивитися результати дослідження",
    pay: "Оплатити обстеження",
    see_summary: "Перегляньте підсумки",
    just_paid: "Ви вже заплатили за це дослідження.",
    go_to_facility: "Ідіть до пункту збору, щоб пройти дослідження.",
  },
  documents: {
    terms: "Регламент сайту",
    privacy_policy: "Політика конфіденційності",
    providers_terms_of_use: "Providers Terms of Use",
  },

  your_settlements: {
    user_full_name: "Ім'я та прізвище",
    address: "Адреса",
    billing_data: "Дані до рахунку-фактури",
    edit_billing_data: "Редагувати дані рахунка-фактури",
    my_settlements: "Мої платежі",
    verify_data_title: "Перевірте свої дані",
    verify_data_text:
      "Надайте дані для платежів, щоб мати можливість оплатити за виписані направлення",
    company_data_title: "Дані фірми",
    company_name: "Назва фірми / клініки",
    nip: "NIP",
    free_from_vat_title: "Ви звільнені від ПДВ?",
    free_from_vat:
      "Я заявляю, що маю право на суб'єктивне звільнення, передбачене ст. 113 абзац. 1 Закону про ПДВ",
    address_title: "Адреса",
    street: "Вулиця",
    house_number: "Номер будинку",
    flat_number: "Номер приміщення",
    postal_code: "Поштовий індекс",
    city: "Місто",
    account_number_title: "Номер банківського рахунку",
    account_number: "Номер банківсього рахунку",
    verify_account: "Перевірити свій обліковий запис",
    your_invoices: "Твої рахунки-фактури",

    checkboxes: {
      check_all: "Виберіть усі",
      agree_terms: "Я заявляю, що прочитав(ла) і приймаю",
      terms: "Умови",
      marketing:
        "Я даю згоду на надсилання комерційної та маркетингової інформації за допомогою електронних засобів зв’язку.",
      agree3:
        "Donec dolor mi, interdum vel arcu vel, gravida sollicitudin est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed accumsan dui et sollicitudin porttitor",
      agree4:
        "Donec sed lacus leo. Fusce fermentum sem sed odio luctus, eu sollicitudin massa elementum. Maecenas pellentesque ligula libero, vel condimentum sem lobortis et",
    },

    invoice: {
      date: "Дата",
      number: "Номер рахунку-фактури",
      amount: "Сума",
      download: "Завантажити рахунок-фактуру",
    },
  },

  share: {
    patient_examinations: "patient_examinations",
    approvals: "Згоди",
    ask_for_access: "Попросіть доступ до результатів досліджень",
  },

  your_stats: {
    issued_referrals: "Видані направлення",
    paid_referrals: "Оплачені направлення",
    completed_referrals: "Виконані направлення",
    canceled_referrals: "Анульовані направлення",
    commission: "Оплата",
    cost: "Вартість дослідження",
  },
  payment: {
    made: "Платіж завершений.",
    canceled: "Платіж анульований.",
    advance: "Очікування на підтвердження платежу.",
    returned: "Платіж повернений.",
    go_to_dashboard: "Перейти на головну панель",
    retry_payment: "Повторити платіж",
  },

  category: {
    bundles_categories: "категорії пакетів",
    examinations_categories: "категорії досліджень",
  },

  collection_points: {
    filters: {
      is_children: "Підходить для дітей",
      is_40_plus: "Профілактика 40+",
      is_ginecology: "Гінекологічний",
      is_sport: "GetLabTest",
      is_swab: "Забір мазків",
      is_parking: "Біля лабораторії",
      open_sunday: "Працює у неділю",
      open_saturday: "Працює у суботу",
    },
    additional_info: "Додаткова інформація",
    additional_open: "additional_open",
    additional_close: "additional_close",
  },

  tests_to_describe: {
    how_much_time_you_have: "how_much_time_you_have",
    min: "min",
    more: "more",
    tests_to_describe: "tests_to_describe",
    orders_can_be_taken_by_others: "orders_can_be_taken_by_others",
    status: "status",
    id_order: "id_order",
    date: "date",
    age_gender: "age_gender",
    due_time: "due_time",
    package: "package",
    test_review_needed: "test_review_needed",
    salary: "salary",
    listing_of_tests: "listing_of_tests",
    see_patient_survey: "see_patient_survey",
    issue_referral_checkup: "issue_referral_checkup",
    description_of_test_results: "description_of_test_results",
    next_patient: "next_patient",
    basic_information: "basic_information",
    gender: "gender",
    age: "age",
    weight: "weight",
    height: "height",
    travelled_to_south_america_or_africa_in_last_12_months:
      "travelled_to_south_america_or_africa_in_last_12_months",
    diseases_in_the_family: "diseases_in_the_family",
    lifestyle: "lifestyle",
    frequency_of_drinking_alcohol: "frequency_of_drinking_alcohol",
    smoking: "smoking",
    diet: "diet",
    supplementation: "supplementation",
    what_measles_takes: "what_measles_takes",
    mental_health_and_well_being: "mental_health_and_well_being",
    what_patient_feels: "what_patient_feels",
    your_welcome_message: "your_welcome_message",
    your_summary: "your_summary",
    good_news: "good_news",
    nothing_is_happening: "nothing_is_happening",
    bad_news: "bad_news",
    very_bad_news: "very_bad_news",
    new_type_of_message: "new_type_of_message",
    when_tests_good_results: "when_tests_good_results",
    when_nothing_happening: "when_nothing_happening",
    when_tests_bad_results: "when_tests_bad_results",
    generate_test_results_desc: "generate_test_results_desc",
    generate_next_tests: "generate_next_tests",
    generate_disease_desc: "generate_disease_desc",
    generate_recommendations: "generate_recommendations",
    show_ai_text: "show_ai_text",
    close_ai_text: "close_ai_text",
    package_name: {
      individual: "individual",
      extended_package: "extended_package",
      basic_package: "basic_package",
    },
    you_dont_have_tests_to_describe: "you_dont_have_tests_to_describe",
    issue_a_referral_will_appear_here_for_description:
      "issue_a_referral_will_appear_here_for_description",
  },
  create_profile: {
    create: "Create a profile",
    description:
      "Thanks to it, patients from all over Poland will be able to schedule online appointments with you and more.",
    synchronized_calendar: "Synchronized calendar",
    set_schedule: "Set schedule",
  },
  doctor_dashboard: {
    have_a_nice_day: "Have a nice day, {name}",
    you_still_have: "You still have {value} visits today",
    appointment_today: "Appointment today",
    test_to_describe: "Test to describe",
    make_a_referal: "Make a referal",
    clinic_revenue: "The revenue of the clinic",
    your_revenue: "your_revenue",
    new_patient: "New patient",
    final_stretch: "You're in the final stretch now",
    vs_last_month: " vs last month",
    tests_to_describe: "Tests to describe",
    see_all: "See all",
    show_less: "Show less",
    due_time: "Due time",
    test_need_review: "test need review",
    provision: "Provision",
    start_a_quick_visit: "Start a quick e-visit",
    visit_description: "Visit without patient registration in the system",
    start_consultation: "Start consultation",
    upcoming_appointments: "Upcoming appointments",
    add_new_appointment: "add new appointment",
    no_appointments: "You don't have any appointments yet. ",
    add_first_appointment: "Add your first appointment.",
    no_tests: "You don't have any test to describe",
    make_your_first_referral: "Make your first referral ",
    paid_referrals: " of which {value} are paid",
  },
  patient_card: {
    add_appointment: "Add Appointment",
    patient_information: "Patient's information",
    patient_appointments: "Appointments",
    patient_history: "Referral History",
    information: {
      personal_information: "Personal information",
      full_name: "Full name",
      gender: "Gender",
      date_of_birth: "Date of birth",
      contact_details: "Contact details",
      phone_number: "Phone number",
      email: "E-mail",
      woman: "Woman",
      man: "Man",
    },
    appointments: {
      table: {
        date: "Date",
        hours: "Hours",
        type_of_service: "Type of service",
        status: "Status",
        referral: "Referral",
      },
      service: "Service",
    },
  },
};
