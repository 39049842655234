import http from "@/utils/axios.js";
import { notify } from "@kyvg/vue3-notification";
import router from "@/router/index.js";

export default {
  namespaced: true,
  state: {
    patient: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirm: "",
      code: "",
      phone_number: "",
      date: "",
      pesel: "",
      isNoPesel: false,
      country_code: null,
      country: null,
      national_number: null,
      accept_terms: false,
      accept_data_processing: true,
      agree_contact: false,
      phone_is_valid: false,
    },
    doctor: {
      code: "",
      first_name: "",
      last_name: "",
      main_medical_category_id: null,
      pwz: "",
      phone_number: "",
      email: "",
      password: "",
      password_confirm: "",
      accept_terms: false,
      accept_marketing: false,
      accept_data_processing: true,
      agree_contact: false,
      commission_option: null,
      country_code: null,
      country: null,
      national_number: null,
      phone_is_valid: false,
      role_id: null,
    },
    errors: [],
    commissionOptions: ["d15p0", "d10p5", "d5p10", "d0p15", "other"],
  },
  getters: {
    getPatient: (state) => state.patient,
    getErrors: (state) => state.errors,
    getDoctor: (state) => state.doctor,
    getCommissionOptions: (state) => state.commissionOptions,
  },
  mutations: {
    setPatient(state, patient) {
      Object.assign(state.patient, patient);
    },
    setDoctor(state, patient) {
      Object.assign(state.doctor, patient);
    },
    resetPatient(state) {
      state.patient = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirm: "",
        code: "",
        phone_number: "",
        date: "",
        pesel: "",
        isNoPesel: false,
        country_code: null,
        country: null,
        national_number: null,
        accept_terms: false,
        accept_data_processing: true,
        agree_contact: false,
        phone_is_valid: false,
      };
    },
    resetDoctor(state) {
      state.doctor = {
        code: "",
        first_name: "",
        last_name: "",
        main_medical_category_id: null,
        pwz: "",
        phone_number: "",
        email: "",
        password: "",
        password_confirm: "",
        accept_terms: false,
        accept_marketing: false,
        accept_data_processing: true,
        agree_contact: false,
        commission_option: null,
        country_code: null,
        country: null,
        national_number: null,
        phone_is_valid: false,
        role_id: null,
      };
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    clearErrors(state) {
      state.errors = [];
    },
    clearDoctor(state) {
      Object.keys(state.doctor).forEach((key) => (state.doctor[key] = ""));
      state.doctor.main_medical_category_id = null;
      state.doctor.accept_terms = false;
      state.doctor.accept_data_processing = true;
      state.doctor.agree_contact = false;
      state.doctor.accept_marketing = false;
      state.doctor.commission_option = null;
    },
  },
  actions: {
    async loginDoctor({ commit, state }, role) {
      return new Promise((resolve, reject) => {
        return http
          .post("/login", { ...state.doctor, role })
          .then(({ data }) => {
            localStorage.authtoken = data.token;
            sessionStorage.removeItem("cart");
            http.defaults.headers["Authorization"] = "Bearer " + data.token;
            commit("clearErrors");
            commit("clearDoctor");
            resolve(data);
          })
          .catch(({ response }) => {
            if (response && response.status === 403) {
              router.push({
                name: "Blocked",
              });
            }

            if (response && response.status === 422) {
              commit("setErrors", response.data.errors);
            }
            reject();
          });
      });
    },
    async registerDoctor({ commit, state }) {
      return new Promise((resolve, reject) => {
        return http
          .post("/register", state.doctor)
          .then(({ data }) => {
            localStorage.authtoken = data.token;
            http.defaults.headers["Authorization"] = "Bearer " + data.token;
            commit("clearErrors");
            resolve(data);
          })
          .catch(({ response }) => {
            if (response && response.status === 422) {
              commit("setErrors", response.data.errors);
            }

            reject();
          });
      });
    },
    async verifyDoctor({ commit, state }) {
      return new Promise((resolve, reject) => {
        return http
          .post("/register/verify", { code: state.doctor.code })
          .then(({ data }) => {
            commit("clearErrors");
            resolve(data);
          })
          .catch(({ response }) => {
            if (response && response.status === 422) {
              commit("setErrors", response.data.errors);
            }

            reject();
          });
      });
    },
    async resendCodeDoctor({ commit }, g_recaptcha_response) {
      commit("clearErrors");
      await http
        .post("/register/send-verification-token", {
          g_recaptcha_response: g_recaptcha_response,
        })
        .then(() => {
          notify({
            type: "success",
            text: "messages.code.resend",
            duration: 5000,
          });
        })
        .catch(({ response }) => {
          if (response && response.status == 422) {
            commit("setErrors", response.data.errors);
          }

          if (response && response.status === 429) {
            notify({
              type: "danger",
              text: "messages.code." + response.data.errors.send_code,
              duration: 5000,
            });
          } else {
            notify({
              type: "danger",
              text: "messages.code.resend_error",
              duration: 5000,
            });
          }
        });
    },
    async loginPatient({ commit, state }) {
      return new Promise((resolve, reject) => {
        return http
          .post("/patient/login", state.patient)
          .then(({ data }) => {
            localStorage.authtoken = data.token;
            http.defaults.headers["Authorization"] = "Bearer " + data.token;
            sessionStorage.removeItem("cart");
            commit("clearErrors");
            resolve(data);
          })
          .catch(({ response }) => {
            if (response && response.status === 422) {
              commit("setErrors", response.data.errors);
            }

            reject();
          });
      });
    },
    async registerPatient({ commit, state }) {
      return new Promise((resolve, reject) => {
        return http
          .post("/patient/send-verification-token", state.patient)
          .then(({ data }) => {
            commit("clearErrors");
            resolve(data);
          })
          .catch(({ response }) => {
            if (response && response.status === 422) {
              commit("setErrors", response.data.errors);
            }

            reject();
          });
      });
    },
    async verifyPatient({ commit, state }) {
      return new Promise((resolve, reject) => {
        return http
          .post("/patient/verify-code", state.patient)
          .then(() => {
            commit("clearErrors");
            resolve();
          })
          .catch(({ response }) => {
            if (response && response.status === 422) {
              commit("setErrors", response.data.errors);
            }

            reject();
          });
      });
    },
    async resendCodePatient({ state }) {
      await http
        .post("/patient/send-verification-token", state.patient)
        .then(() => {
          notify({
            type: "info",
            text: "messages.code.resend",
            duration: 5000,
          });
        })
        .catch(({ response }) => {
          if (response && response.status === 422) {
            notify({
              type: "danger",
              text: "messages.code.resend_error",
              duration: 5000,
            });
          }
        });
    },
    async firstLoginPatient({ commit, state }) {
      return new Promise((resolve, reject) => {
        return http
          .post("/patient/first-login", state.patient)
          .then(({ data }) => {
            localStorage.authtoken = data.token;
            http.defaults.headers["Authorization"] = "Bearer " + data.token;
            commit("clearErrors");
            resolve(data);
          })
          .catch(({ response }) => {
            if (response && response.status === 422) {
              commit("setErrors", response.data.errors);
            }
            reject();
          });
      });
    },
    async changeContext({ commit }, role) {
      return new Promise((resolve, reject) => {
        return http
          .post("/change-context", { role })
          .then(({ data }) => {
            localStorage.authtoken = data.token;
            sessionStorage.removeItem("cart");
            http.defaults.headers["Authorization"] = "Bearer " + data.token;
            commit("clearErrors");
            commit("clearDoctor");
            resolve(data);
          })
          .catch(({ response }) => {
            if (response && response.status === 403)
              router.push({
                name: "Blocked",
              });
            if (response && response.status === 422) {
              commit("setErrors", response.data.errors);
            }

            reject();
          });
      });
    },
  },
};
