export default {
  methods: {
    notifySuccess(text, duration = 5000) {
      this.$notify({
        type: "success",
        text,
        duration,
      });
    },
    notifyError(text, duration = 5000) {
      this.$notify({
        type: "danger",
        text,
        duration,
      });
    },
    notifyInfo(text, duration = 5000) {
      this.$notify({
        type: "info",
        text,
        duration,
      });
    },
  },
};
