import store from "@/store";

export default function checkIsVideoInWindow({ next }) {
  if (
    store.getters["call/getMeet"] &&
    store.getters["call/getUserMeetingStarted"]
  )
    store.commit("call/setIsVideoInWindow", true);
  else {
    store.commit("call/setIsVideoInWindow", false);
  }

  return next();
}
