import http from "@/utils/axios";

const getAdminStatus = (item) => {
  let status;
  if (item.status === "paid") {
    status = "paid";
  } else if (
    item.status === "completed" &&
    !["being_edited", "done"].includes(item.test_to_describe_status)
  ) {
    status = "completed";
  } else if (item.test_to_describe_status) {
    status = item.test_to_describe_status;
  } else {
    status = item.status;
  }

  return status;
};

export default {
  namespaced: true,
  state: {
    referrals: [],
    pagination: {
      currentPage: 1,
      total: 1,
    },
    currentReferral: null,
    stats: {
      referrals_count: 0,
      avg_referrals_count: 0,
      avg_referrals_price: 0,
    },
    commissionRange: [1, 100],
    priceRange: [1, 1000],
    patientsOptions: [],
    maxQuantityOfTests: 100,
    maxTestPrice: 2000,
    patientOrders: [],
  },
  getters: {
    getReferrals: (state) => state.referrals,
    getPatientOrders: (state) => state.patientOrders,
    getPagination: (state) => state.pagination,
    getCurrentReferral: (state) => state.currentReferral,
    getStats: (state) => state.stats,
    getCommissionRange: (state) => state.commissionRange,
    getPriceRange: (state) => state.priceRange,
    getMaxQuantityOfTests: (state) => state.maxQuantityOfTests,
    getMaxTestPrice: (state) => state.maxTestPrice,
    getPatientsOptions: (state) =>
      state.patientsOptions.map((patient) => ({
        id: patient.id,
        name: patient.full_name,
      })),
  },
  mutations: {
    setReferrals(state, referrals) {
      state.referrals = referrals.data.map((item) => {
        item.admin_status = getAdminStatus(item);
        return item;
      });
      state.pagination.currentPage = referrals.meta.current_page;
      state.pagination.total = referrals.meta.last_page;
    },
    setPatientOrders(state, referrals) {
      state.patientOrders = referrals.data.map((item) => {
        item.admin_status = getAdminStatus(item);
        return item;
      });
      state.pagination.currentPage = referrals.meta.current_page;
      state.pagination.total = referrals.meta.last_page;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },
    setCurrentReferral(state, currentReferral) {
      state.currentReferral = currentReferral;
    },
    setCurrentReferralPatient(state, patient) {
      state.currentReferral.patient = patient;
    },
    setStats(state, stats) {
      state.stats = stats;
    },
    setCommissionRange(state, commissionRange) {
      state.commissionRange = commissionRange;
    },
    setPriceRange(state, priceRange) {
      state.priceRange = priceRange;
    },
    setMaxQuantityOfTests(state, quantity) {
      state.maxQuantityOfTests = quantity;
    },
    setPatientsOptions(state, patients) {
      state.patientsOptions = patients;
    },
    setMaxTestPrice(state, price) {
      state.maxTestPrice = price;
    },
    clearReferrals(state) {
      state.referrals = [];
      state.pagination.currentPage = 1;
      state.pagination.total = 1;
    },
    clearPatientOrders(state) {
      state.patientOrders = [];
      state.pagination.currentPage = 1;
      state.pagination.total = 1;
    },
    clearCurrentReferral(state) {
      state.currentReferral = null;
    },
  },
  actions: {
    async fetchReferrals(
      { commit, state },
      { time, date_range, filters, sortBy } = {
        time: null,
        date_range: null,
        filters: null,
        sortBy: null,
      }
    ) {
      const { data } = await http.get(`/referral`, {
        params: {
          page: state.pagination.currentPage,
          time: time,
          date_range: date_range,
          ...filters,
          ...sortBy,
        },
      });
      commit("setReferrals", data);
    },
    async fetchAdminStats(
      { commit },
      { time = "current_month", date_range = null }
    ) {
      const { data } = await http.get("admin/referrals/stats", {
        params: {
          time: time,
          date_range: date_range,
        },
      });
      commit("setStats", data.stats);
      commit("doctors/setTopDoctors", data.top_doctors, { root: true });
    },
    async checkResults(
      { commit, state },
      { id, time = "current_month", date_range = null, filters, sortBy }
    ) {
      const { data } = await http.get(`referrals/check-results/${id}`, {
        params: {
          page: state.pagination.currentPage,
          time: time,
          date_range: date_range,
          ...filters,
          ...sortBy,
        },
      });
      commit("setReferrals", data.referrals);
    },
    async fetchDoctorReferrals(
      { commit, state },
      { date_range, filters, sortBy, search, doctor_id } = {}
    ) {
      const { data } = await http.get("referrals/all-referrals", {
        params: {
          page: state.pagination.currentPage,
          date_range,
          search,
          doctor_id: doctor_id,
          ...filters,
          ...sortBy,
        },
      });
      commit("setReferrals", data.referrals);
      commit("setStats", data.stats);
      commit("doctors/setTopDoctors", data.top_doctors, { root: true });
      commit("referrals/setPriceRange", data.price_range, {
        root: true,
      });
    },
    async fetchPatientsOptions({ commit }, payload) {
      const response = await http.get("doctor/filters/patients", {
        params: { ...payload },
      });
      commit("setPatientsOptions", response.data);
    },
    async fetchMaxQuantityOfTests({ commit }, payload) {
      const response = await http.get("doctor/filters/quantity-of-tests", {
        params: { ...payload },
      });
      commit("setMaxQuantityOfTests", response.data);
    },
    async fetchMaxTestPrice({ commit }, payload) {
      const response = await http.get("doctor/filters/test-price", {
        params: { ...payload },
      });
      commit("setMaxTestPrice", response.data);
    },
    async fetchAdminReferrals(
      { commit, state },
      { time = "current_month", date_range = null, filters, sortBy }
    ) {
      const { data } = await http.get("admin/referrals", {
        params: {
          page: state.pagination.currentPage,
          time: time,
          date_range: date_range,
          ...filters,
          ...sortBy,
        },
      });
      commit("setReferrals", data.referrals);
      commit("setStats", data.stats);
      commit("doctors/setTopDoctors", data.top_doctors, { root: true });
      commit("referrals/setPriceRange", data.price_range, {
        root: true,
      });
    },
    async fetchAdminPatientOrders(
      { commit, state },
      { time = "current_month", date_range = null, filters, sortBy }
    ) {
      const { data } = await http.get("admin/orders", {
        params: {
          page: state.pagination.currentPage,
          time: time,
          date_range: date_range,
          ...filters,
          ...sortBy,
        },
      });
      commit("setPatientOrders", data.referrals);
      commit("referrals/setPriceRange", data.price_range, {
        root: true,
      });
    },
    async fetchCurrentReferral({ commit }, id) {
      return await http.get(`/referrals/${id}`).then(({ data }) => {
        commit("setCurrentReferral", data.referral);
      });
    },
    sendAndDownloadReferral({ state }) {
      const windowReference = window.open();

      return http({
        url: `/referrals/send-and-download/${state.currentReferral.id}`,
        method: "POST",
      }).then((response) => {
        windowReference.location = response.data.url;
      });
    },
  },
};
