import http from "@/utils/axios";

export default {
  namespaced: true,
  state: {
    awsMeeting: null, // aws
    attendee: null,
    isLoading: false,
    chatActive: false,
    chatInitialized: false,
    meet: null,
    conversation_id: null,
    conversation_token: null,
    errors: {},
    isVideoInWindow: false,
    callIsMounted: false,
    phoneMounted: false,
    userMeetingStarted: false,
    renderChatAndVideo: true,
    calendarUUID: null,
    messages: [],
  },
  getters: {
    isLoading: (state) => state.isLoading,
    isChatActive: (state) => state.chatActive,
    isChatInitialized: (state) => state.chatInitialized,
    getAwsMeeting: (state) => state.awsMeeting,
    getAttendee: (state) => state.attendee,
    getMeet: (state) => state.meet,
    getReferralId: (state) => state.referralId,
    getConversationId: (state) => state.conversation_id,
    getConversationMessages: (state) => state.messages,
    getConversationToken: (state) => state.conversation_token,
    getMeetDiagnosis: (state) => state.meet?.diagnosis,
    getMeetInterview: (state) => state.meet?.case_history,
    getMeetRecommendations: (state) => state.meet?.recommendations,
    getErrors: (state) => state.errors,
    getIsVideoInWindow: (state) => state.isVideoInWindow,
    getCallIsMounted: (state) => state.callIsMounted,
    getPhoneMounted: (state) => state.phoneMounted,
    getUserMeetingStarted: (state) => state.userMeetingStarted,
    getRenderChatAndVideo: (state) => state.renderChatAndVideo,
    getCalendarUUID: (state) => state.calendarUUID,
  },
  mutations: {
    setCalendarUUID(state, value) {
      state.calendarUUID = value;
    },
    setRenderChatAndVideo(state, value) {
      state.renderChatAndVideo = value;
    },
    setUserMeetingStarted(state, value) {
      state.userMeetingStarted = value;
    },
    setIsVideoInWindow(state, value) {
      state.isVideoInWindow = value;
    },
    setCallIsMounted(state, value) {
      state.callIsMounted = value;
    },
    setPhoneMounted(state, value) {
      state.phoneMounted = value;
    },
    setLoading(state, payload) {
      state.isLoading = payload;
    },
    toggleChat(state) {
      state.chatActive = !state.chatActive;
    },
    setChatActive(state, payload) {
      state.chatActive = payload;
    },
    setChatInitialized(state, payload) {
      state.chatInitialized = payload;
    },
    setMeetingInfo(state, payload) {
      state.awsMeeting = payload.Meeting;
      state.attendee = payload.Attendees[0];
    },
    setMeet(state, payload) {
      state.meet = payload.meet;
    },
    setConversationMessages(state, payload) {
      state.messages = payload;
    },
    pushConversationMessage(state, payload) {
      state.messages.push(payload);
    },
    setMeetValue(state, data) {
      Object.assign(state.meet, data);
    },
    setReferralId(state, referralId) {
      state.referralId = referralId;
    },
    setConversation(state, payload) {
      state.conversation_id = payload.conversation_id;
      state.conversation_token = payload.token;
    },
    pushMeetIcd(state, icd) {
      state.meet.diagnosis.push(icd);
    },
    removeMeetIcd(state, icd) {
      const index = state.meet.diagnosis.findIndex(
        (diagnosis) => diagnosis.id === icd.id
      );
      state.meet.diagnosis.splice(index, 1);
    },
    clearAllMeetingData(state) {
      state.awsMeeting = null;
      state.attendee = null;
      state.isLoading = false;
      state.chatActive = false;
      state.chatInitialized = false;
      state.meet = null;
      state.conversation_id = null;
      state.conversation_token = null;
      state.errors = {};
      state.isVideoInWindow = false;
      state.callIsMounted = false;
      state.phoneMounted = false;
      state.userMeetingStarted = false;
      state.calendarUUID = null;
    },
  },
  actions: {
    async endMeeting({ state }) {
      return http.post(`/doctor/meetings/end/${state.calendarUUID}`);
    },
    async fetchActiveMeeting({ commit }, id) {
      commit("setLoading", true);
      const { data } = await http.get(`/meet/active/${id}`);
      commit("setMeetingInfo", data);
      commit("setLoading", false);
    },
    async fetchConversationMessages({ commit }, id) {
      const { data } = await http.get(`/meet/meet/${id}/messages`);
      commit("setConversationMessages", data.messages);
    },
    setChatInitialized({ commit }, state) {
      commit("setChatInitialized", state);
    },
    async fetchMeet({ commit }, uid) {
      const { data } = await http.get(`/meet/${uid}`);
      commit("setMeet", data);
      commit("referrals/setCurrentReferral", data.referral, { root: true });
      if (data.results) {
        commit("results/setResultsData", data.results, { root: true });
      }
      commit("testsToDescribe/setQuiz", data.quiz, { root: true });

      if (data.conversation_id) {
        commit("setConversation", data);
      }
    },
    updateMeetingCard({ state }) {
      return http.post(`/doctor/meetings/${state.meet.uid}/card`, {
        recommendations: state.meet.recommendations,
        case_history: state.meet.case_history,
        diagnosis: state.meet.diagnosis.map((item) => item.id),
      });
    },
    resetVideoDraggableStyles() {
      const el = document.getElementById("draggableVideo");
      if (el) {
        el.style.removeProperty("top");
        el.style.removeProperty("left");
      }
    },
  },
};
