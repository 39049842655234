import store from "@/store";

export default function hasNoBillingData({ next, router }) {
  let hasBillingData = store.getters["user/getHasBillingData"];

  if (!hasBillingData) {
    return next();
  }

  return router.push({ name: "Dashboard" });
}
