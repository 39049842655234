export default {
  namespaced: true,
  state: {
    userLoader: false,
    contentLoader: false,
    resultsLoader: false,
    generateLoader: false,
    savingLoader: false,
    fullPageLoader: false,
  },
  getters: {
    getUserLoaderStatus: (state) => state.userLoader,
    getContentLoaderStatus: (state) => state.contentLoader,
    getResultsLoader: (state) => state.resultsLoader,
    getGenerateLoader: (state) => state.generateLoader,
    getSavingLoader: (state) => state.savingLoader,
    getFullPageLoader: (state) => state.fullPageLoader,
  },
  mutations: {
    userIsLoading(state) {
      state.userLoader = true;
    },
    userLoaded(state) {
      state.userLoader = false;
    },
    contentIsLoading(state) {
      state.contentLoader = true;
    },
    contentLoaded(state) {
      state.contentLoader = false;
    },
    resultsIsLoading(state) {
      state.resultsLoader = true;
    },
    resultsLoaded(state) {
      state.resultsLoader = false;
    },
    generateIsLoading(state) {
      state.generateLoader = true;
    },
    generateLoaded(state) {
      state.generateLoader = false;
    },
    savingIsLoading(state) {
      state.savingLoader = true;
    },
    savingLoaded(state) {
      state.savingLoader = false;
    },
    setPageLoader(state, payload) {
      state.fullPageLoader = payload;
    },
  },
};
