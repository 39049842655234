import store from "@/store";

export default function hasMedicalLicence({ next, router, from }) {
  if (store.getters["user/getCurrentUser"].has_medical_licence) {
    return next();
  }

  store.commit("modals/openModal", "doctorNoMedicalLicenceModal");

  return router.push(from);
}
