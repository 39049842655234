import Echo from "laravel-echo";

let echo = null;
const token = localStorage.getItem("authtoken");

if (process.env.VUE_APP_WS_ENABLE === "true") {
  window.Pusher = require("pusher-js");

  echo = new Echo({
    broadcaster: "pusher",
    key: process.env.VUE_APP_WS_KEY,
    wsHost: process.env.VUE_APP_WS_HOST,
    cluster: process.env.VUE_APP_WS_CLUSTER,
    wsPort: process.env.VUE_APP_WS_PORT,
    forceTLS: false,
    disableStats: false,
    encrypted: true,
    authEndpoint: process.env.VUE_APP_WS_AUTH_ENDPOINT,
    auth: {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
      },
    },
  });
}

export default echo;
