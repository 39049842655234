import http from "@/utils/axios";

export default {
  namespaced: true,
  state: {
    doctorServices: [],
    doctorMedicalCategory: null,
    currentDoctorServices: [],
    availableTimes: [
      {
        name: "time.mins15",
        value: 15,
      },
      {
        name: "time.mins30",
        value: 30,
      },
      {
        name: "time.mins45",
        value: 45,
      },
      {
        name: "time.hour",
        value: 60,
      },
    ],
    servicesCount: 0,
  },
  getters: {
    getDoctorServices: (state) => state.doctorServices,
    getDoctorMedicalCategory: (state) => state.doctorMedicalCategory,
    getCurrentDoctorServices: (state) => state.currentDoctorServices,
    getAvailableTimes: (state) => state.availableTimes,
    getServicesCount: (state) => state.servicesCount,
  },
  mutations: {
    setDoctorServices(state, payload) {
      state.doctorServices = payload;
    },
    setServicesCount(state, count) {
      state.servicesCount = count;
    },
    setDoctorMedicalCategory(state, payload) {
      state.doctorMedicalCategory = payload;
    },
    setCurrentDoctorServices(state, payload) {
      state.currentDoctorServices = payload;
    },
    removeDoctorService(state, id) {
      state.doctorServices = state.doctorServices.filter(
        (item) => item.id !== id
      );
    },
    editDoctorService(state, service) {
      const idx = state.doctorServices.findIndex(
        (item) => item.id === service.id
      );
      if (!(typeof idx === "number") || idx === -1) return;
      state.doctorServices[idx] = service;
    },
    addDoctorService(state, service) {
      const serviceData = {
        ...service,
        id: -((state.doctorServices.length ?? 0) + 1),
      };
      state.doctorServices.push(serviceData);
    },
  },
  actions: {
    async fetchDefaultDoctorServices({ commit }) {
      let response = await http.get("doctor/services");
      let checkAvailable = true;
      if (typeof response.data.user_specialization_services === "object") {
        const array = Object.values(response.data.user_specialization_services);
        if (array.length && array[0].services.length) {
          checkAvailable = false;
          commit("setDoctorServices", array[0].services);
          commit("setDoctorMedicalCategory", array[0].main_medical_category_id);
        }
      }

      if (checkAvailable) {
        response = await http.get("doctor/specializations/services");
        const [firstSpecServices] = Object.values(
          response.data.specialization_services
        );
        commit("setDoctorServices", firstSpecServices.services);
        commit(
          "setDoctorMedicalCategory",
          firstSpecServices.main_medical_category_id
        );
      }
    },
    async fetchCurrentDoctorServices({ commit }, params = {}) {
      let { data } = await http.get("doctor/services", {
        params: params,
      });
      commit("setCurrentDoctorServices", data.user_specialization_services);
      commit("setServicesCount", data.services_count);
    },
  },
};
