export const deleteNestedKeyAndClearEmpty = (obj, keys) => {
  const [key, ...restKeys] = keys;

  if (
    restKeys.length === 0 ||
    (obj[key] && deleteNestedKeyAndClearEmpty(obj[key], restKeys))
  ) {
    delete obj[key];
    return Object.keys(obj).length === 0;
  }

  return false;
};

export const getValueByPath = (obj, path) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

export const makeNestedObjectFromObjectKeyPath = (obj) => {
  const result = {};

  for (const [key, value] of Object.entries(obj)) {
    const keys = key.split(".");

    let current = result;

    for (let i = 0; i < keys.length; i++) {
      const part = keys[i];

      if (i === keys.length - 1) {
        current[part] = value;
      } else {
        if (!current[part]) {
          current[part] = {};
        }
        current = current[part];
      }
    }
  }

  return result;
};
