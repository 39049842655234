import store from "@/store";

export default async function auth({ next, router }) {
  store.commit("auth/clearErrors");
  const token = localStorage.getItem("authtoken");

  if (!token) {
    return router.push({ name: "LoginDoctor", query: {} });
  }

  if (store.getters["user/getCurrentUser"]) {
    return next();
  }

  window.Intercom("update");
  await store
    .dispatch("user/fetchCurrentUser")
    .then(() => {
      store.commit("loader/userLoaded");

      return next();
    })
    .catch(({ response }) => {
      store.commit("user/setCurrentUser", null);
      localStorage.removeItem("authtoken");

      if (response && response.status === 403) {
        return router.push({
          name: "Blocked",
        });
      }

      return router.push({
        name: "LoginDoctor",
      });
    });
}
