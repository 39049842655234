import http from "@/utils/axios";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    examinations: [],
    resultsToReview: [],
    resultsExpectedRange: [],
    referralGroups: [],
    description: null,
    relatedReferralGroup: null,
    selectedResult: null,
  },
  getters: {
    getExaminations: (state) => state.examinations,
    getResultsToReview: (state) => state.resultsToReview,
    getResultsExpectedRange: (state) => state.resultsExpectedRange,
    getReferralGroups: (state) => state.referralGroups,
    getDescription: (state) => state.description,
    getRelatedReferralGroup: (state) => state.relatedReferralGroup,
    getSelectedResult: (state) => state.selectedResult,
  },
  mutations: {
    setExaminations(state, examinations) {
      state.examinations = examinations;
    },
    setResultsToReview(state, resultsToReview) {
      state.resultsToReview = resultsToReview;
    },
    setResultsExpectedRange(state, resultsExpectedRange) {
      state.resultsExpectedRange = resultsExpectedRange;
    },
    setDescription(state, description) {
      state.description = description;
    },
    setSelectedResult(state, selectedResult) {
      state.selectedResult = selectedResult;
    },
    setRelatedReferralGroup(state, group) {
      state.relatedReferralGroup = group;
    },
    setResultsData(state, data) {
      state.examinations = data.examinations;
      state.resultsToReview = data.toReview;
      state.resultsExpectedRange = data.expectedRange;
      state.description = data.describe;
      state.relatedReferralGroup = data.relatedReferralGroup;
      state.selectedResult = data.examinations?.[0]?.results?.[0];
    },
    clearResults(state) {
      state.examinations = [];
      state.resultsToReview = [];
      state.resultsExpectedRange = [];
      state.referralGroups = [];
    },
  },
  actions: {
    async fetchResults({ commit }, id) {
      await http.get(`referrals/results/${id}`).then(({ data }) => {
        commit("setExaminations", data.examinations);
        commit("setResultsToReview", data.toReview);
        commit("setResultsExpectedRange", data.expectedRange);
        commit("setDescription", data.describe);
        commit("setRelatedReferralGroup", data.relatedReferralGroup);
        commit("setSelectedResult", data.examinations?.[0]?.results?.[0]);
      });
    },
    downloadResults(_, id) {
      http({
        url: `/referrals/download-result/${id}`,
        method: "GET",
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          const type = response.headers["content-type"];
          let filename =
            response.headers["content-disposition"].split("filename=")[1];
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: type })
          );
          let link = document.createElement("a");
          link.href = url;
          link.download = filename ?? "results.zip";
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            URL.revokeObjectURL(url);
            link.remove();
          }, 1000);
        })
        .catch(({ response }) => {
          if (response && response.status === 404) {
            notify({
              type: "danger",
              text: "messages.download.no_files",
              duration: 5000,
            });
          } else {
            notify({
              type: "danger",
              text: "messages.download.failure",
              duration: 5000,
            });
          }
        });
    },
  },
};
