import auth from "@/middleware/auth";
import hasRoles from "@/middleware/hasRoles";

export default [
  {
    path: "",
    meta: {
      middlewares: [auth, hasRoles],
      roles: ["admin"],
    },
    children: [
      {
        path: "doctors",
        name: "AdminDoctors",
        component: () =>
          import(
            /* webpackChunkName: "admin-doctors" */ "@/views/Admin/Doctors.vue"
          ),
      },
      {
        path: "raports",
        name: "AdminRaports",
        component: () =>
          import(
            /* webpackChunkName: "admin-doctors" */ "@/views/Admin/Raports.vue"
          ),
      },
      {
        path: "raports-links",
        name: "AdminRaportsLinks",
        component: () =>
          import(
            /* webpackChunkName: "admin-doctors" */ "@/views/Admin/DailyRaportLinks.vue"
          ),
      },
      {
        path: "invoices",
        name: "AdminInvoices",
        component: () =>
          import(
            /* webpackChunkName: "admin-invoices" */ "@/views/Admin/Invoices.vue"
          ),
      },
      {
        path: "createdinvoices",
        name: "AdminCreatedInvoices",
        component: () =>
          import(
            /* webpackChunkName: "admin-invoices" */ "@/views/Admin/CreatedInvoices.vue"
          ),
      },
      {
        path: "features",
        name: "Features",
        component: () =>
          import(
            /* webpackChunkName: "admin-features" */ "@/views/Admin/Features.vue"
          ),
      },
      {
        path: "data-management",
        name: "DataManagement",
        component: () =>
          import(
            /* webpackChunkName: "admin-features" */ "@/views/Admin/DataManagement.vue"
          ),
      },
      {
        path: "raports",
        name: "Raports",
        component: () =>
          import(
            /* webpackChunkName: "admin-features" */ "@/views/Admin/Raports.vue"
          ),
      },
      {
        path: "doctor-create",
        name: "AdminCreateDoctor",
        component: () =>
          import(
            /* webpackChunkName: "admin-doctor-create" */ "@/views/Admin/DoctorCreate.vue"
          ),
      },
      {
        path: "doctor-edit/:id",
        name: "AdminUpdateDoctor",
        props: (route) => ({ id: route.params.id * 1 }),
        component: () =>
          import(
            /* webpackChunkName: "admin-doctor-create" */ "@/views/Admin/DoctorCreate.vue"
          ),
      },
      {
        path: "doctors",
        name: "AdminDoctorsMessage",
        component: () =>
          import(
            /* webpackChunkName: "admin-doctors-message" */ "@/views/Admin/MessageForm.vue"
          ),
      },
      {
        path: "promotions",
        name: "Promotions",
        component: () =>
          import(
            /* webpackChunkName: "promotions" */ "@/views/Admin/Promotions.vue"
          ),
      },
      {
        path: "inbound-sms",
        name: "InboundSms",
        component: () =>
          import(
            /* webpackChunkName: "inbound-sms" */ "@/views/Admin/InboundSms.vue"
          ),
      },
      {
        path: "inbound-sms/:number",
        name: "InboundSmsNumber",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "inbound-sms" */ "@/views/Admin/InboundSmsNumber.vue"
          ),
      },
    ],
  },

  {
    path: "admin",
    meta: {
      middlewares: [auth, hasRoles],
      roles: ["admin", "super-clinic-admin"],
    },
    children: [
      {
        path: "referrals",
        name: "AdminReferrals",
        component: () =>
          import(
            /* webpackChunkName: "admin-orders" */ "@/views/Admin/Referrals.vue"
          ),
      },
      {
        path: "orders",
        name: "AdminOrders",
        component: () =>
          import(
            /* webpackChunkName: "admin-patient-orders" */ "@/views/Admin/Orders.vue"
          ),
      },
      {
        path: "stats",
        name: "AdminStats",
        component: () =>
          import(
            /* webpackChunkName: "admin-stats" */ "@/views/Admin/Stats.vue"
          ),
      },
      {
        path: "visits",
        name: "AdminPatientsVisits",
        component: () =>
          import(
            /* webpackChunkName: "admin-patients-visits" */ "@/views/Admin/Visits.vue"
          ),
      },

      {
        path: "visit/:visitId",
        name: "AdminPatientsVisitDetails",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-patients-visit-details" */ "@/views/Admin/VisitDetails.vue"
          ),
      },
      {
        path: "doctor/:id",
        name: "AdminDoctorCard",
        props: (route) => ({ id: route.params.id * 1 }),
        component: () =>
          import(
            /* webpackChunkName: "admin-doctor-card" */ "@/views/Admin/DoctorCard.vue"
          ),
      },
    ],
  },
];
