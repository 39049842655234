import auth from "@/middleware/auth";
import hasRoles from "@/middleware/hasRoles";
import isSmsVerified from "@/middleware/isSmsVerified";
import isSmsNotVerified from "@/middleware/isSmsNotVerified";
import hasNoBillingData from "@/middleware/hasNoBillingData";
import isUserNotBlocked from "@/middleware/isUserNotBlocked";

export default {
  path: "/doctor",
  component: () =>
    import(/* webpackChunkName: "guest" */ "@/layouts/Doctor/Auth.vue"),
  meta: {
    middlewares: [auth, hasRoles, isSmsVerified, isUserNotBlocked],
    roles: ["doctor"],
  },
  children: [
    {
      path: "collection-point",
      name: "DashboardDoctorFacility",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dashboard-collection-point" */ "@/views/Auth/Doctor/Facility.vue"
        ),
    },
    {
      path: "collection-facility",
      name: "AuthFalicityDoctor",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "auth-facility-doctor" */ "@/views/Auth/Doctor/Facility.vue"
        ),
    },
    {
      path: "services",
      name: "AuthServicesDoctor",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "auth-services-doctor" */ "@/views/Auth/Doctor/Services.vue"
        ),
    },
    {
      path: "patient-hours",
      name: "AuthPatientHoursDoctor",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "auth-patient-hours-doctor" */ "@/views/Auth/Doctor/PatientHours.vue"
        ),
    },
    {
      path: "billing-data",
      name: "AuthBillingDataDoctor",
      meta: {
        middlewares: [auth, hasRoles, isSmsVerified, hasNoBillingData],
      },
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "auth-billing-data-doctor" */ "@/views/Auth/Doctor/BillingData.vue"
        ),
    },
    {
      path: "completed",
      name: "AuthDoctorCompleted",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "auth-doctor-compleated" */ "@/views/Auth/Doctor/Completed.vue"
        ),
    },
    {
      path: "code",
      name: "AuthCodeDoctor",
      meta: { middlewares: [auth, hasRoles, isSmsNotVerified] },
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "auth-code-doctor" */ "@/views/Auth/Doctor/Code.vue"
        ),
    },
  ],
};
