import store from "@/store";

export default function isActivatedUser({ next, router }) {
  let userStatus = store.getters["user/getCurrentUserStatus"];

  if (userStatus === "active") {
    return next();
  }

  store.commit("modals/openModal", "doctorNotActive");

  return router.push({ name: "Dashboard" });
}
