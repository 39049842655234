import store from "@/store";

export default function clearCartData({ next, from }) {
  if (from.name === "DoctorReferralEdit") {
    sessionStorage.removeItem("cart");
    store.commit("cart/clearAll");
  }

  return next();
}
