import http from "@/utils/axios";

const defaultDays = Array.from({ length: 7 }, (_, idx) => ({
  enabled: idx < 5,
  day_of_week: idx + 1,
  hours: [{ from: "08:00", to: "16:00" }],
}));

export default {
  namespaced: true,
  state: {
    meeting_interval: 0,
    days: defaultDays,
    change_date: null,
    end_date: null,
    meetingIntervalOptions: [
      {
        name: "time.mins0",
        value: 0,
      },
      {
        name: "time.mins2",
        value: 2,
      },
      {
        name: "time.mins5",
        value: 5,
      },
      {
        name: "time.mins10",
        value: 10,
      },
      {
        name: "time.mins15",
        value: 15,
      },
    ],
    nameOfDays: [
      "",
      "week_days.monday",
      "week_days.tuesday",
      "week_days.wednesday",
      "week_days.thursday",
      "week_days.friday",
      "week_days.saturday",
      "week_days.sunday",
    ],
  },
  getters: {
    getOpenHours: (state) => state.days,
    getMeetingInterval: (state) => state.meeting_interval,
    getMeetingIntervalOptions: (state) => state.meetingIntervalOptions,
    getNameOfDays: (state) => state.nameOfDays,
    getChangeDate: (state) => state.change_date,
    getEndDate: (state) => state.end_date,
  },
  mutations: {
    setMeetingInterval(state, value) {
      state.meeting_interval = value;
    },
    setChangeDate(state, value) {
      state.change_date = value;
    },
    setEndDate(state, value) {
      state.end_date = value;
    },
    editDay(state, value) {
      const editIdx = state.days.findIndex(
        (item) => item.day_of_week === value.day_of_week
      );
      if (editIdx === -1) return;

      state.days[editIdx] = value;
    },
    toggleEnabled(state, idx) {
      if (state.days[idx]) {
        state.days[idx].enabled = !state.days[idx].enabled;
      }
    },
    setDays(state, newState) {
      state.days = newState;
    },
    resetScheduleDays(state) {
      state.meeting_interval = 0;
      state.days = defaultDays;
      state.change_date = null;
      state.end_date = null;
    },
  },
  actions: {
    async getCurrentUserSchedule({ commit, rootState }) {
      const user = rootState.user.currentUser;
      const { data } = await http.get(
        `calendar/schedule/open/${user.clinic.id}/${user.id}`
      );
      if (data.schedule) {
        commit("setMeetingInterval", data.schedule.meeting_interval);
        commit(
          "setDays",
          data.schedule.days.map((item) => {
            item.enabled = item.hours.length > 0;
            return item;
          })
        );
      } else {
        commit("setMeetingInterval", 0);
        commit("setDays", defaultDays);
      }
    },
  },
};
