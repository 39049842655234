export default {
  path: "/medical-entity",
  component: () =>
    import(
      /* webpackChunkName: "medical-entity-auth-layout" */ "@/layouts/MedicalEntity/Auth.vue"
    ),
  children: [
    {
      path: "register",
      name: "RegisterMedicalEntity",
      meta: { requiresAuth: false },
      component: () =>
        import(
          /* webpackChunkName: "register-medical-entity" */ "@/views/Auth/MedicalEntity/Register"
        ),
    },
    {
      path: "code",
      name: "AuthCodeMedicalEntity",
      meta: { requiresAuth: true, smsVerified: 0 },
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "auth-code-medical-entity" */ "@/views/Auth/Doctor/Code.vue"
        ),
    },
    {
      path: "facility-name",
      name: "AuthNameMedicalEntity",
      meta: { requiresAuth: true, smsVerified: 1 },
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "auth-code-medical-entity" */ "@/views/Auth/MedicalEntity/Name.vue"
        ),
    },
    {
      path: "services",
      name: "AuthServicesMedicalEntity",
      meta: { requiresAuth: true, smsVerified: 1 },
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "auth-services-medical-entity" */ "@/views/Auth/MedicalEntity/Services.vue"
        ),
    },
    {
      path: "billing-data",
      name: "AuthBillingDataMedicalEntity",
      meta: {
        requiresAuth: true,
        smsVerified: 1,
        hasBillingData: false,
      },
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "auth-billing-data-medical-entity" */ "@/views/Auth/Doctor/BillingData.vue"
        ),
    },
    {
      path: "completed",
      name: "AuthMedicalEntityCompleted",
      meta: {
        requiresAuth: true,
        smsVerified: 1,
      },
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "auth-medical-entity-compleated" */ "@/views/Auth/MedicalEntity/Completed.vue"
        ),
    },
  ],
};
