export default {
  namespaced: true,
  state: {
    mobile: false,
    unreadMessagesCount: 0,
  },
  getters: {
    isMobile: (state) => state.mobile,
    getUnreadMessagesCount: (state) => state.unreadMessagesCount,
  },
  mutations: {
    setMobile(state, isMobile) {
      state.mobile = isMobile;
    },
    setUnreadMessagesCount(state, count) {
      state.unreadMessagesCount = count;
    },
  },
};
