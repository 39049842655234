import http from "@/utils/axios";

export default {
  namespaced: true,
  state: {
    invoices: [],
    pagination: {
      currentPage: 1,
      total: 1,
    },

    totalPriceRange: [1, 10000],
  },
  getters: {
    getInvoices: (state) => state.invoices,
    getPagination: (state) => state.pagination,
    getTotalPriceRange: (state) => state.totalPriceRange,
  },
  mutations: {
    setInvoices(state, invoices) {
      state.invoices = invoices.data;
      state.pagination.currentPage = invoices.meta.current_page;
      state.pagination.total = invoices.meta.last_page;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },
    clearInvoices(state) {
      state.invoices = [];
    },
  },
  actions: {
    async fetchInvoices({ commit, state }, { filters }) {
      await http
        .get(`/admin/invoices/created-invoices`, {
          params: {
            page: state.pagination.currentPage,
            ...filters,
          },
        })
        .then(({ data }) => {
          commit("setInvoices", data);
        });
    },
  },
};
