export const ROLES = {
  DOCTOR: "doctor",
  DIETITIAN: "dietitian",
  ADMIN: "admin",
  AI_CHAT: "ai-chat",
  SUPER_CLINIC_ADMIN: "super-clinic-admin",
  CLINIC_OWNER: "clinic_owner",
  CLINIC_WORKER: "clinic_worker",
  CLINIC_IT_SPECIALIST: "clinic_it_specialist",
};
