import http from "@/utils/axios";
import router from "@/router/index.js";

export default {
  namespaced: true,
  state: {
    message: {
      title: "",
      message: "",
    },
    doctors: [],
    errors: {},
  },
  getters: {
    getMessage: (state) => state.message,
    getDoctors: (state) => state.doctors,
    getErrors: (state) => state.errors,
    getDoctorsCount: (state) => state.doctors.length,
  },
  mutations: {
    setMessageValue(state, message) {
      Object.assign(state.message, message);
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    clearErrors(state) {
      state.errors = [];
    },
    pushDoctor(state, id) {
      state.doctors.push(id);
    },
    removeDoctor(state, id) {
      const index = state.doctors.findIndex((doctor) => doctor === id);
      state.doctors.splice(index, 1);
    },
    clearAll(state) {
      state.message.title = "";
      state.message.message = "";
      state.doctors = [];
      state.errors = {};
    },
  },
  actions: {
    async sendMessage({ state, commit }) {
      await http
        .post(`/admin/doctors/send-message`, {
          users: state.doctors,
          title: state.message.title,
          message: state.message.message,
        })
        .then(() => {
          commit("clearErrors");
          commit("modals/openModal", "messageRecipientsCount", { root: true });
        })
        .catch(({ response }) => {
          if (response && response.status === 422) {
            commit("setErrors", response.data.errors);
          }
          if (response && response.status === 500)
            router.push({ name: "Error" });
        });
    },
  },
};
