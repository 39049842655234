export default {
  namespaced: true,
  state: {
    statuses: [],
    accountStatuses: [
      {
        label: "filters.status",
        value: null,
      },
      {
        label: "doctor_status.active",
        value: "active",
      },
      {
        label: "doctor_status.inactive",
        value: "inactive",
      },
      {
        label: "doctor_status.blocked",
        value: "blocked",
      },
      {
        label: "doctor_status.deactivated",
        value: "deactivated",
      },
      {
        label: `doctor_status.all`,
        value: "all",
      },
    ],
    patientStatuses: [
      {
        label: "filters.status",
        value: null,
      },
      {
        label: "doctor_status.active",
        value: "active",
      },
      {
        label: "doctor_status.blocked",
        value: "blocked",
      },
      {
        label: `doctor_status.all`,
        value: "all",
      },
    ],
    invoiceStatuses: [
      {
        label: "filters.status",
        value: null,
      },
      {
        label: "admin.invoices.statuses.new",
        value: "new",
      },
      {
        label: "admin.invoices.statuses.paid",
        value: "paid",
      },
      // {
      //   label: "admin.invoices.statuses.to_send",
      //   value: "to_send",
      // },
      {
        label: `admin.invoices.statuses.all`,
        value: "all",
      },
    ],
    discounts: [
      {
        label: "filters.patient_discount_percent",
        value: null,
      },
      {
        label: "patient_discount_percent.zero",
        value: "0",
      },
      {
        label: "patient_discount_percent.five",
        value: "5",
      },
      {
        label: "patient_discount_percent.ten",
        value: "10",
      },
      {
        label: `patient_discount_percent.fifteen`,
        value: "15",
      },
      {
        label: `patient_discount_percent.all`,
        value: "all",
      },
    ],
    referralType: [
      {
        label: "filters.type",
        value: null,
      },
      {
        label: "referral_type.doctor",
        value: "doctor",
      },
      {
        label: "referral_type.home",
        value: "home",
      },
      {
        label: "referral_type.all",
        value: "all",
      },
    ],
    visitTypes: [
      {
        label: "filters.visits.type",
        value: null,
      },
      {
        label: "visit_type.video",
        value: "video",
      },
      {
        label: "visit_type.phone_call",
        value: "phone-call",
      },
      {
        label: "visit_type.offline",
        value: "offline",
      },
      {
        label: "visit_type.all",
        value: "all",
      },
    ],
    invoiceClientStatuses: [
      {
        label: "admin.invoices.statuses.to_send",
        value: "to_send",
      },
      {
        label: "admin.invoices.statuses.sent",
        value: "sent",
      },
    ],
    referralStatuses: [],
    visitStatuses: [
      {
        label: "filters.status",
        value: null,
      },
      {
        label: "visits.status.waiting_for_results",
        value: "waiting_for_results",
      },
      {
        label: "visits.status.canceled_by_doctor",
        value: "canceled_by_doctor",
      },
      {
        label: "visits.status.to_be_scheduled",
        value: "to_be_scheduled",
      },
      {
        label: "visits.status.no_show",
        value: "no_show",
      },
      {
        label: "visits.status.rescheduled",
        value: "rescheduled",
      },
      {
        label: "visits.status.scheduled",
        value: "scheduled",
      },
      {
        label: "visits.status.completed",
        value: "completed",
      },
      {
        label: "visits.status.ongoing",
        value: "ongoing",
      },
      {
        label: "visit_type.all",
        value: "all",
      },
    ],
  },
  getters: {
    getStatuses: (state) => state.statuses,
    getReferralStatuses: (state) => state.referralStatuses,
    getInvoiceStatuses: (state) => state.invoiceStatuses,
    getReferralType: (state) => state.referralType,
    getVisitTypes: (state) => state.visitTypes,
    getVisitStatuses: (state) => state.visitStatuses,
    getStatusId: (state) => (name) =>
      state.statuses.find((status) => status.value === name),
    getReferralStatusId: (state) => (name) =>
      state.referralStatuses.find((status) => status.value === name),
    getAccountStatuses: (state) => state.accountStatuses,
    getPatientStatuses: (state) => state.patientStatuses,
    getDoctorStatusId: (state) => (name) =>
      state.accountStatuses.find((status) => status.value === name),
    getPatientStatusId: (state) => (name) =>
      state.patientStatuses.find((status) => status.value === name),
    getInvoiceStatusId: (state) => (name) =>
      state.invoiceStatuses.find((status) => status.value === name),
    getInvoiceClientStatusId: (state) => (name) =>
      state.invoiceClientStatuses.find((status) => status.value === name),
    getReferralTypeId: (state) => (name) =>
      state.referralType.find((status) => status.value === name),
    getDiscounts: (state) => state.discounts,
    getDiscountId: (state) => (name) =>
      state.discounts.find((discount) => discount.value === name),
    getInvoiceClientStatuses: (state) => state.invoiceClientStatuses,
  },
  mutations: {
    setStatuses(state, statuses) {
      state.statuses = statuses;
    },
    setReferralStatuses(state, statuses) {
      state.referralStatuses = statuses;
    },
  },
  actions: {
    initStatuses({ commit, rootGetters }) {
      let userRole = rootGetters["user/getCurrentUserRole"];
      if (["admin", "super-clinic-admin"].includes(userRole))
        userRole = "doctor";
      commit("setStatuses", [
        {
          label: "filters.status",
          value: null,
        },
        {
          label: `referral_status.${userRole}.issued`,
          value: "issued",
        },
        {
          label: `referral_status.${userRole}.paid`,
          value: "paid",
        },
        {
          label: `referral_status.${userRole}.canceled`,
          value: "canceled",
        },
        {
          label: `referral_status.${userRole}.completed`,
          value: "completed",
        },
        {
          label: `referral_status.${userRole}.partial_results`,
          value: "partial_results",
        },
      ]);
    },
    initAdminReferralStatuses({ commit, rootGetters }) {
      let userRole = rootGetters["user/getCurrentUserRole"];
      if (userRole === "admin") userRole = "doctor";
      commit("setReferralStatuses", [
        {
          label: "filters.status",
          value: null,
        },
        {
          label: `referral_status.referrals.canceled`,
          value: "canceled",
        },
        {
          label: `referral_status.referrals.paid`,
          value: "paid",
        },
        {
          label: `referral_status.referrals.partial_results`,
          value: "partial_results",
        },
        {
          label: `referral_status.referrals.completed`,
          value: "completed",
        },
        {
          label: `referral_status.referrals.being_edited`,
          value: "being_edited",
        },
        {
          label: `referral_status.referrals.done`,
          value: "done",
        },
      ]);
    },
  },
};
