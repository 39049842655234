import http from "@/utils/axios";

export default {
  namespaced: true,
  state: {
    medicalCategories: [],
    currentServices: [],
    roles: [],
  },
  getters: {
    getMedicalCategories: (state) => state.medicalCategories,
    getCurrentServices: (state) => state.currentServices,
    getRoles: (state) => state.roles,
  },
  mutations: {
    setRoles(state, roles) {
      state.roles = roles;
    },
    setMedicalCategories(state, payload) {
      state.medicalCategories = payload;
    },
    setCurrentServices(state, payload) {
      state.currentServices = payload;
    },
    removeClinicService(state, { specialization_id, service_id }) {
      const specializationIndex = state.currentServices.findIndex(
        (spec) => spec.main_medical_category_id == specialization_id
      );
      state.currentServices[specializationIndex].services =
        state.currentServices[specializationIndex].services.filter(
          (item) => item.id !== service_id
        );
    },
    editClinicService(state, service) {
      const specializationIndex = state.currentServices.findIndex(
        (spec) =>
          spec.main_medical_category_id == service.main_medical_category_id
      );
      const idx = state.currentServices[specializationIndex].services.findIndex(
        (item) => item.id === service.id
      );
      if (!(typeof idx === "number") || idx === -1) return;
      state.currentServices[specializationIndex].services[idx] = service;
    },
    addClinicService(state, service) {
      const specializationIndex = state.currentServices.findIndex(
        (spec) =>
          spec.main_medical_category_id == service.main_medical_category_id
      );
      const serviceData = {
        ...service,
        id: -(
          (state.currentServices[specializationIndex].services.length ?? 0) +
          1 +
          specializationIndex * 10
        ),
      };
      state.currentServices[specializationIndex].services.push(serviceData);
    },
  },
  actions: {
    async fetchDefaultClinicServices({ commit }) {
      let response = await http.get("clinic/services");
      let checkAvailable = true;
      if (response.data.services_count > 0) {
        checkAvailable = false;
        commit(
          "setCurrentServices",
          response.data.clinic_specialization_services
        );
        commit("setMedicalCategories", response.data.main_medical_categories);
      }

      if (checkAvailable) {
        response = await http.get("clinic/services/available");
        commit(
          "setCurrentServices",
          response.data.clinic_available_specialization_services
        );
        commit("setMedicalCategories", response.data.main_medical_categories);
      }
    },
    async fetchRoles({ commit }) {
      http.get("clinic/roles").then(({ data }) => {
        const roles = data.roles.map((role) => {
          return {
            name: "auth.roles." + role.name,
            value: role.id,
            base_name: role.name,
          };
        });
        commit("setRoles", roles);
      });
    },
  },
};
