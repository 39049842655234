import auth from "@/middleware/auth";
import hasRoles from "@/middleware/hasRoles";

export default {
  path: "",
  meta: {
    middlewares: [auth, hasRoles],
    roles: ["super-clinic-admin"],
  },
  children: [
    {
      path: "test-describe",
      name: "TestDescribe",
      component: () =>
        import(
          /* webpackChunkName: "test-describe" */ "@/views/Admin/TestDescribe.vue"
        ),
    },
    {
      path: "test-describe/edit/:id",
      name: "EditTestDescribe",
      props: (route) => ({ id: route.params.id * 1 }),
      component: () =>
        import(
          /* webpackChunkName: "edit-test-describe" */ "@/views/Admin/EditTestDescribe.vue"
        ),
    },
    {
      path: "staff",
      children: [
        {
          path: "",
          name: "StaffList",
          component: () =>
            import(/* webpackChunkName: "staff" */ "@/views/Admin/Staff.vue"),
        },
        {
          path: ":id",
          name: "StaffCard",
          props: (route) => ({ id: route.params.id * 1 }),
          component: () =>
            import(
              /* webpackChunkName: "staff-card" */ "@/components/Layouts/Admin/StaffCard.vue"
            ),
          children: [
            {
              path: "",
              name: "StaffProfile",
              component: () =>
                import(
                  /* webpackChunkName: "staff-profile" */ "@/views/Staff/Profile.vue"
                ),
            },
            {
              path: "settings",
              name: "StaffAccountSettings",
              component: () =>
                import(
                  /* webpackChunkName: "account-settings" */ "@/views/Staff/Account.vue"
                ),
            },
            {
              path: "services",
              name: "StaffAccountServices",
              component: () =>
                import(
                  /* webpackChunkName: "account-settings" */ "@/views/Staff/Services.vue"
                ),
            },
            {
              path: "patient_appointment_hours",
              name: "StaffAccountAppointmentHours",
              component: () =>
                import(
                  /* webpackChunkName: "account-settings" */ "@/views/Staff/AppointmentHours.vue"
                ),
            },
            {
              path: "billing_information",
              name: "StaffBillingInformation",
              component: () =>
                import(
                  /* webpackChunkName: "account-settings" */ "@/views/Staff/BillingInformation.vue"
                ),
            },
          ],
        },
        {
          path: ":doctorid/referral-history",
          name: "StaffReferralHistory",
          component: () =>
            import(
              /* webpackChunkName: "staff-referral-history" */ "@/views/Doctor/ReferralHistory.vue"
            ),
        },
      ],
    },
    {
      path: "patient",
      children: [
        {
          path: "list",
          name: "AdminPatientList",
          component: () =>
            import(
              /* webpackChunkName: "admin-patient-list" */ "@/views/Admin/PatientList.vue"
            ),
        },
        {
          path: "card/:patientId",
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "patient-card-admin" */ "@/components/Layouts/Admin/PatientCard.vue"
            ),
          children: [
            {
              path: "",
              name: "AdminPatientCard",
              props: true,
              component: () =>
                import(
                  /* webpackChunkName: "admin-patient-information" */ "@/views/Admin/Patient/Information.vue"
                ),
            },
            {
              path: "referral-history",
              name: "AdminReferralHistory",
              props: true,
              component: () =>
                import(
                  /* webpackChunkName: "admin-referral-history" */ "@/views/Admin/Patient/ReferralHistory.vue"
                ),
            },
            {
              path: "visits-history",
              name: "AdminVisitsHistory",
              props: true,
              component: () =>
                import(
                  /* webpackChunkName: "admin-visits-history" */ "@/views/Admin/Patient/Appointments.vue"
                ),
            },
          ],
        },
      ],
    },
  ],
};
