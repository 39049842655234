import { createRouter, createWebHistory } from "vue-router";
import globalRoutes from "@/router/global";
import authRoutes from "@/router/auth";
import doctorAuthRoutes from "@/router/doctorAuth";
import medicalEntityRoutes from "@/router/medicalEntity";
import dashboardRoutes from "@/router/dashboard";

const routes = [
  authRoutes,
  doctorAuthRoutes,
  medicalEntityRoutes,
  dashboardRoutes,
  globalRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

const middlewareFactory = (context, middlewares, index) => {
  const nextMiddleware = middlewares[index];

  if (!nextMiddleware) {
    return context.next;
  }

  return () => {
    const nextMiddlewareFn = middlewareFactory(context, middlewares, index + 1);

    nextMiddleware({ ...context, next: nextMiddlewareFn });
  };
};

router.beforeEach((to, from, next) => {
  if (to.meta.middlewares) {
    let middlewares = to.meta.middlewares;

    if (to.meta.excludeMiddlewares) {
      middlewares = middlewares.filter(
        (middleware) => !to.meta.excludeMiddlewares.includes(middleware.name)
      );
    }

    if (to.meta.extraMiddlewares) {
      middlewares = [...middlewares, ...to.meta.extraMiddlewares];
    }

    const nextMiddleware = middlewareFactory(
      { from, next, router, to },
      middlewares,
      0
    );

    return nextMiddleware();
  }

  return next();
});

export default router;
