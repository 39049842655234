import store from "@/store";

export default function checkIsVideoMounted({ next, to }) {
  if (["CallVideo", "CallPhone"].includes(to.name)) {
    store.commit("call/setCallIsMounted", false);
    store.commit("call/setPhoneMounted", false);
  } else {
    store.commit("call/setCallIsMounted", true);
    store.commit("call/setPhoneMounted", true);
  }

  return next();
}
