export default {
  path: "/auth",
  component: () =>
    import(/* webpackChunkName: "guest" */ "@/layouts/Guest.vue"),
  children: [
    {
      path: "login",
      name: "LoginDoctor",
      component: () =>
        import(
          /* webpackChunkName: "login-doctor" */ "@/views/Auth/Doctor/Login.vue"
        ),
    },
    {
      path: "create-account",
      name: "CreateAccount",
      component: () =>
        import(
          /* webpackChunkName: "create-account" */ "@/views/Auth/CreateAccount.vue"
        ),
    },
    {
      path: "password/email",
      name: "PasswordEmail",
      component: () =>
        import(
          /* webpackChunkName: "password-email" */ "@/views/Auth/Password/Email.vue"
        ),
    },
    {
      path: "password/reset/:code",
      name: "PasswordReset",
      component: () =>
        import(
          /* webpackChunkName: "password-reset" */ "@/views/Auth/Password/Reset.vue"
        ),
    },
    {
      path: "doctor",
      children: [
        {
          path: "register",
          name: "RegisterDoctor",
          component: () =>
            import(
              /* webpackChunkName: "register-doctor" */ "@/views/Auth/Doctor/Register.vue"
            ),
        },
        {
          path: "register/usa",
          name: "RegisterDoctorUSA",
          component: () =>
            import(
              /* webpackChunkName: "register-doctor" */ "@/views/Auth/Doctor/Register.vue"
            ),
        },
        {
          path: "email-verify/:code",
          name: "AuthEmailVerify",
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "auth-doctor-email-verify" */ "@/views/Auth/Doctor/EmailVerify.vue"
            ),
        },
        {
          path: "create/:code",
          name: "DoctorAccountCreated",
          component: () =>
            import(
              /* webpackChunkName: "account-created" */ "@/views/Auth/Doctor/AccountCreated.vue"
            ),
        },
      ],
    },
  ],
};
