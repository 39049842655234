import http from "@/utils/axios";

export default {
  namespaced: true,
  state: {
    referralGroups: [],
    pagination: {
      currentPage: 1,
      total: 1,
    },
    currentReferral: null,
    commissionRange: [1, 100],
    priceRange: [1, 1000],
  },
  getters: {
    getPagination: (state) => state.pagination,
    getCurrentReferral: (state) => state.currentReferral,
  },
  mutations: {
    setReferralGroups(state, referralGroups) {
      state.referralGroups = referralGroups.data;
      state.pagination.currentPage = referralGroups.meta.current_page;
      state.pagination.total = referralGroups.meta.last_page;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },
    setCurrentReferral(state, currentReferral) {
      state.currentReferral = currentReferral;
    },
    clearCurrentReferral(state) {
      state.currentReferral = null;
    },
  },
  actions: {
    async checkResults(
      { commit, state },
      { id, time = "current_month", date_range = null, filters, sortBy }
    ) {
      const { data } = await http.get(`referrals/check-results/${id}`, {
        params: {
          page: state.pagination.currentPage,
          time: time,
          date_range: date_range,
          ...filters,
          ...sortBy,
        },
      });
      commit("setReferralGroups", data);
    },
    async fetchCurrentReferral({ commit }, id) {
      return await http.get(`/referrals/${id}`).then(({ data }) => {
        commit("setCurrentReferral", data.referral);
      });
    },
  },
};
